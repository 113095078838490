import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
} from "react-router-dom";

import { getArtist, addArtist, updateArtist, deleteArtist } from "../../redux/actions/artists";

import { Table, TableContainer, TableHead, TableRow, TableCell, tableCellClasses, Paper, TableBody, Button} from '@mui/material';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
//import Typography from '@mui/material/Typography';

import { AddCircle, Edit } from '@mui/icons-material';

import ModalArtist from '../../components/modals/artist';

class Artist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            artists:[],
            postPerPage:10,
            page:1,
            lastId:0,
            availableMore:true,
            err:{},
            isModalOpen:false,
            isLoading:false,
            modalMode:"add",
            iditem:null,
            artist:{
                name:null,
                slug:null,
                description:null,
                weburl:null,
                instagramurl:null,
                mixcloudurl:null,
                soundcloudurl:null,
                idcountry:null,
                idimage:null
            }
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        this.loadMoreShow = this.loadMoreShow.bind(this);
    }

    handleDelete(e){
        e.preventDefault();
        this.setState({isLoading:true});
        this.props.deleteArtist({id:this.state.iditem}).then((res)=>{
            if(res.code === 200){
                var joined = this.state.artists;
                let obj = joined.findIndex(o => o.id === res.data.id);
                joined.splice(obj, 1);
                this.setState({ isLoading:false, artists: joined }, function(){
                    this.closeModal();
                })
            }else{
                alert("Something Error");
            }
        })
    }

    handleUpdate(e){
        e.preventDefault();
        this.setState({isLoading:true});
        this.props.updateArtist({id:this.state.iditem, artist:this.state.artist}).then((res)=>{
            if(res.code === 200){
                var joined = this.state.artists;
                let obj = joined.findIndex(o => o.id === res.data.id);
                joined[obj] = res.data;
                this.setState({ isLoading:false, artists: joined }, function(){
                    this.closeModal();
                })
            }else{
                alert("Something Error");
            }
        })
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({isLoading:true});
        this.props.addArtist({artist:this.state.artist}).then((res)=>{
            if(res.code === 200){
                var joined = [res.data, ...this.state.artists];
                this.setState({ isLoading:false, artists: joined }, function(){
                    this.closeModal();
                })
            }else{
                alert("Something Error");
            }
        })
    }

    handleChange(event, newValue){
        if(newValue !== undefined){
            if(newValue !== null){
                event.currentTarget.name = event.target.getAttribute('name');
                event.currentTarget.value = newValue.id;
            }
        }

        this.setState({
            artist: {
                ...this.state.artist,
                [event.currentTarget.name]: event.currentTarget.value
            }
        });
    }

    closeModal(){
        this.setState({
            isModalOpen:false,
            iditem:null,
            artist:{
                name:null,
                slug:null,
                description:null,
                weburl:null,
                instagramurl:null,
                mixcloudurl:null,
                soundcloudurl:null,
                idcountry:null,
                idimage:null
            }
        });
    }

    openModal(event){
        let dataiditem = parseInt(event.currentTarget.getAttribute('data-iditem'));
        let modalmode = event.currentTarget.getAttribute('modal-mode');
        
        if(isNaN(dataiditem) !== true){
            let obj = this.state.artists.find(o => o.id === dataiditem);
            this.setState({iditem: obj.id, artist:obj}, function(){
                this.setState({isModalOpen:true, modalMode:modalmode});
            })
        }else{
            this.setState({isModalOpen:true, modalMode:modalmode});            
        }
    }

    loadMoreShow(){
        this.props.getArtist({slug:"all", page:this.state.page}).then((res) =>{
            if(res.data !== null){
                this.setState({artists:this.state.artists.concat(res.data), page:this.state.page + 1, lastId:res.data[res.data.length - 1].id});
            }else{
                this.setState({availableMore:false});
            }
        }).catch((err)=>{
            console.log("Something Error");
            console.log(err);
        })
    }

    componentDidMount(){
        this.props.getArtist({slug:"all", page:1, perpage: this.state.postPerPage}).then((res) =>{
            if(res.data !== null){
                this.setState({artists:res.data, page:this.state.page + 1});  
                if(res.length < this.state.postPerPage){
                    this.setState({availableMore:false})
                }          
            }else{
                this.setState({artists:[], availableMore:false})
            }
        }).catch((err)=>{
            console.log("Something Error");
            console.log(err);
        })
    }

    render() {
        const theme = createTheme();
        const StyledTableCell = styled(TableCell)(({ theme }) => ({
            [`&.${tableCellClasses.head}`]: {
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
            },
            [`&.${tableCellClasses.body}`]: {
                fontSize: 14,
            },
        }));
        
        const StyledTableRow = styled(TableRow)(({ theme }) => ({
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
            '&:last-child td, &:last-child th': {
                border: 0,
            },
        }));

        function retrieveImage(data){
            if(data !== null){
                return data.fullurl;
            }else{
                return "https://via.placeholder.com/100";
            }            
        }

        return (
            <ThemeProvider theme={theme}>
            <Box sx={{ display: 'block' }}>
                <Container component="div" className='panel-section-container' maxWidth="xl">
                    <Box component="div" sx={{ display:'flex', alignItems:'right', justifyContent:'right', marginBottom:'10px'}}>
                        <Button variant="contained" endIcon={<AddCircle />} onClick={this.openModal}> Create Artist </Button>
                    </Box>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Cover</StyledTableCell>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell align="center">Country</StyledTableCell> 
                                {/* <StyledTableCell align="center">Status</StyledTableCell> */}
                                <StyledTableCell align="right">Actions</StyledTableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.artists.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell align="center"><img width={50} height={50}
                                        src={`${retrieveImage(row.image)}?w=164&h=164&fit=crop&auto=format`}
                                        srcSet={`${retrieveImage(row.image)}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        alt={row.title}
                                        loading="lazy"
                                    /></StyledTableCell>
                                    <StyledTableCell>{row.name}</StyledTableCell>
                                    <StyledTableCell className="artist-country" align="center">{row.country.name} 
                                    {(row.country !== null)? <img                                        
                                        className="artist-country-flag"
                                        loading="lazy"
                                        width="25"
                                        src={`https://flagcdn.com/w20/${row.country.initial.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${row.country.initial.toLowerCase()}.png 2x`}
                                        alt=""
                                    />:""}</StyledTableCell>
                                    {/* <StyledTableCell align="center">{row.is_active.toString()}</StyledTableCell> */}
                                    <StyledTableCell align="right"><Button variant="contained" endIcon={<Edit />} onClick={this.openModal} modal-mode="edit" data-iditem={row.id}> Edit </Button></StyledTableCell>
                                </StyledTableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{
                        p: 1,
                        m: 1,
                        bgcolor: 'background.paper',
                        }}>
                        {(this.state.availableMore !== false)?<Button onClick={this.loadMoreShow} variant="contained" sx={{
                            margin:'auto',
                            display:'block'
                        }}>Load More</Button>:''}                
                    </Box>
                </Container>
            </Box>
            { (this.state.isModalOpen !== false)?<ModalArtist isLoading={this.state.isLoading} artist={this.state.artist} modalMode={this.state.modalMode} isModalOpen={this.state.isModalOpen} closeModal={this.closeModal} handleChange={this.handleChange} handleDelete={this.handleDelete} handleUpdate={this.handleUpdate} handleSubmit={this.handleSubmit}/>:'' }
            </ThemeProvider>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getArtist: artistData => dispatch(getArtist(artistData)),
        addArtist: artistData => dispatch(addArtist(artistData)),
        updateArtist: artistData => dispatch(updateArtist(artistData)),
        deleteArtist: artistData => dispatch(deleteArtist(artistData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Artist));