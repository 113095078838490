import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Link,
  // NavLink,
  withRouter,
} from "react-router-dom";

import Box from "@mui/material/Box";
// import Grid from '@mui/material/Grid';
// import Typography from '@mui/material/Typography';
// import NorrmLogoWhite from "../assets/images/norrm-logo-white.png";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      err: null,
    };
  }

  render() {
    return (
      <Box className="nav-link">
        <ul className="main-menu">
          {/* <li><Link to='/' onClick={this.props.closeMenu}>Home</Link></li> */}
          <li>
            <Link to="/about" onClick={this.props.closeMenu}>
              About
            </Link>
          </li>
          <li>
            <Link to="/show" onClick={this.props.closeMenu}>
              Radio
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/show" onClick={this.props.closeMenu}>
                  Shows
                </Link>
              </li>
              <li>
                <Link to="/program" onClick={this.props.closeMenu}>
                  Programs
                </Link>
              </li>
              <li>
                <Link to="/artist" onClick={this.props.closeMenu}>
                  Artists
                </Link>
              </li>
              {/* <li><Link to='/genre' onClick={this.props.closeMenu}>Genre</Link></li> */}
              {/* <li><Link to='/tag' onClick={this.props.closeMenu}>Tag</Link></li> */}
            </ul>
          </li>
          <li>
            <Link to="/press" onClick={this.props.closeMenu}>
              Press
            </Link>
          </li>
          <li>
            <Link to="/radiobar" onClick={this.props.closeMenu}>
              Radio Bar
            </Link>
          </li>
          <li>
            <Link to={{ pathname: "https://www.tokopedia.com/norrm?source=universe&st=product" }} target="_blank">
              Shop
            </Link>
          </li>
        </ul>
      </Box>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    //logout: userData => dispatch(logout(userData))
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
