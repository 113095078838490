import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import ReactGA from 'react-ga';

import {
    NavLink,
    withRouter
} from "react-router-dom";

import { loadMoreGenre } from "../redux/actions/genres";

import { 
    Box, 
    Grid, 
    Button, 
    Typography 
} from '@mui/material';

class Genre extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:null
        };
        
        this.loadMore = this.loadMore.bind(this);
    }

    loadMore(){
        let page = (this.props.genre.length / process.env.REACT_APP_PERPAGE) + 1;
        this.props.loadMoreGenre({slug:"all", page:page, perpage:process.env.REACT_APP_PERPAGE}).then((res)=>{
            
        })
    }

    componentDidMount(){
        ReactGA.pageview(this.props.location.pathname);
    }

    render() {
        let genreContainer = "";
        let genres = this.props.genre;
        if(genres.length !== 0){
            genreContainer = genres.map((item, idx)=>{
                return(
                    <Fragment key={idx}>
                        <Grid item component={NavLink} to={"/genre/"+item.slug} data-type="genre" data-quick={idx} className='genre-container-item' sx={{backgroundImage:"url("+encodeURI(item.image?.fullurl)+")"}}>
                            <Typography className="genre-container-item-text">{item.name}</Typography>
                        </Grid>
                    </Fragment>
                )
            })
        }

        return (
        <Box component="div" className="show-page" fullwidth="true">            
            <Box>
                <Box className='archive-container'>
                    <Box className='section-container-header'>
                        <Typography>Genres</Typography>
                    </Box>
                    <Grid container spacing={0} className="grid-container">
                        {genreContainer}
                    </Grid>
                    <Box className='archive-loadmore-container' sx={{width:"100%", borderTop:"1px solid #000"}}>
                        {this.props.genreFullLoaded !== true?<Button sx={{width:"100%"}} onClick={this.loadMore}>Load More</Button>:""}                        
                    </Box>
                </Box>
            </Box>
        </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadMoreGenre: data => dispatch(loadMoreGenre(data)),
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Genre));