import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import ReactGA from 'react-ga';

import {
    NavLink,
    withRouter
} from "react-router-dom";

import { 
    getTagDetails
} from "../redux/actions/tags";

import { 
    Box, 
    Grid, 
    Typography 
} from '@mui/material';

import LoadingSection from '../components/sections/loadings';

class TagDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shows:[],
            loaded:false,
            tag:null,
            err:null
        };
    }

    componentDidMount(){
        ReactGA.pageview(this.props.location.pathname);

        this.props.getTagDetails({slug:this.props.match.params.slug}).then((res)=>{
            if(res.code === 200){            
                this.setState({tag:res.data, loaded:true, shows:res.data.shows});
            }
        })
    }

    render() {
        let ShowContainer = "";
        let shows = this.state.shows;
        if(shows !== undefined && shows.length !== 0){
            ShowContainer = shows.map((item, idx)=>{
                return(
                <Fragment key={idx}>
                    <Grid component={NavLink} to={"/show/"+item.slug} data-type="artist" data-quick={idx} item className='grid-container-item' sx={{backgroundImage:"url("+encodeURI(item.image.fullurl)+")"}}></Grid>                    
                </Fragment>
                )
            })
        }

        return (
            <Box component="div" className="tag-detail-page">
                {(this.state.loaded !== false)?
                    (this.state.tag !== null)?
                        <Box sx={{ minHeight:"450px" }} className="tag-container">
                            {this.state.tag.image!==null?<Box sx={{
                                display:"block",
                                minHeight:"450px",
                                borderBottom:"1px solid #000000",
                                backgroundSize:"cover",
                                backgroundPosition:"center",
                                backgroundImage:"url("+encodeURI(this.state.tag.image?.fullurl)+")",
                            }}></Box>:""}                    

                            <Box sx={{ 
                                padding:"30px",                         
                                textAlign:"center",
                                borderBottom:"1px solid #000000",
                            }}>
                                <Typography sx={{fontSize:"24px", fontWeight:"800"}}>{this.state.tag.name}</Typography>
                                {this.state.tag.caption!==null?<Typography sx={{marginTop:"20px"}}>{this.state.tag.caption}</Typography>:""}
                            </Box>

                            <Grid container spacing={0} className="grid-container">
                                {ShowContainer}
                            </Grid>
                        </Box>
                        :"Tag Not Found"
                :<LoadingSection />}                
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getTagDetails: data => dispatch(getTagDetails(data)),
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TagDetail));