import React, { Component } from 'react';
import { connect } from "react-redux";
import ReactGA from 'react-ga';

import {
    // Link,
    withRouter
} from "react-router-dom";

import { getPress, loadMorePress } from "../redux/actions/presses";

import { 
    Box,
    // Typography 
} from '@mui/material';

// http://165.22.111.34:8080/embed/video
// https://tv.norrm.com/embed/video
// http://165.22.111.34:8080/hls/stream.m3u8
// https://tv.norrm.com/hls/stream.m3u8
// http://165.22.111.34:8080/embed/chat/readwrite
// https://tv.norrm.com/embed/chat/readwrite

class Live extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:null
        };
    }
    
    // <iframe
    // src="https://your.host/embed/video"
    // title="Owncast"
    // height="350px" width="550px"
    // referrerpolicy="origin"
    // scrolling="no"
    // allowfullscreen>
    // </iframe> 

    componentDidMount(){
        ReactGA.pageview(this.props.location.pathname);
    }

    render() {
        return (
        <Box component="div" className="about-page" fullwidth="true">
            <Box sx={{
                backgroundColor: "#343434",
                textAlign:"center"
            }}>
                <Box 
                    sx={{
                        minHeight:{
                            md:"500px", 
                            xs:"230px"
                        }, 
                        maxWidth: "800px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        aspectRatio: {
                            md:"16/9.8",
                            xs:"16/11.5"
                        },
                        padding: "20px",
                        display: "flex", 
                        justifyContent: "center", 
                        alignItems: "center",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        alignContent: "center",
                        outline: "none"
                    }}
                >
                    <iframe
                    src="https://tv.norrm.com/embed/video"
                    title="Norrm TV"
                    style={{
                        width:"100%", 
                        height:"100%", 
                        border:"none"
                    }}
                    referrerPolicy="origin"
                    scrolling="no"
                    allowFullScreen>
                    </iframe>
                </Box>
            </Box>
        </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getPress: data => dispatch(getPress(data)),
        loadMorePress: data => dispatch(loadMorePress(data)),
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Live));