import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import ReactGA from 'react-ga';

import {
    NavLink,
    withRouter,
} from "react-router-dom";

import { playMixcloud } from "../../redux/actions/utils";
import { loadMoreShow} from "../../redux/actions/shows";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Tags from '../stacks/tags';
import Hosts from '../stacks/hosts';
import Genres from '../stacks/genres';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

class Shows extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastPos:0,
            err:null
        };
        
        this.loadMore = this.loadMore.bind(this);
        this.expandGrid = this.expandGrid.bind(this);
        this.closeExpand = this.closeExpand.bind(this);
        this.playArchives = this.playArchives.bind(this);        
    }

    loadMore(e){
        let page = (this.props.show.length / process.env.REACT_APP_PERPAGE) + 1;
        this.props.loadMoreShow({slug:"all", page:page, perpage:process.env.REACT_APP_PERPAGE});
        // .then((res)=>{
        //     // console.log(res);
        //     // if(res.fullload !== undefined){
        //     //     ctx.setState({canLoadmore:false});
        //     // }
        // })
    }

    playArchives(e){
        this.props.playMixcloud({key:e.currentTarget.attributes["data-mixcloudkey"].value});
    }

    expandGrid(e){
        this.setState({lastPos:window.pageYOffset});
        let slug = e.target.attributes.getNamedItem('data-slug').value;
        ReactGA.pageview("/show/"+slug);

        let type = e.target.attributes.getNamedItem('data-type').value;
        let valll = e.target.attributes.getNamedItem('data-quick').value;
        let docex = document.querySelector("."+type+".expand-"+valll);
        
        const expanded = document.getElementsByClassName("grid-expanded");
        if(expanded.length !== 0){
            expanded[0].classList.toggle("is-hidden");
            expanded[0].classList.toggle("grid-expanded");
        }

        docex.classList.toggle("is-hidden");
        docex.classList.toggle("grid-expanded");

        if(type === "artist"){
            const element = document.querySelector("#show-expand-"+valll);
            const topPos = element.getBoundingClientRect().top + window.pageYOffset - 35;
            window.scrollTo({
                top: topPos,
                behavior: 'smooth'
            })
        }else if(type === "program"){
            const element = document.querySelector("#program-expand-"+valll);
            const topPos = element.getBoundingClientRect().top + window.pageYOffset - 35;
            window.scrollTo({
                top: topPos,
                behavior: 'smooth'
            })            
        }else if(type === "press"){
            const element = document.querySelector("#press-expand-"+valll);
            const topPos = element.getBoundingClientRect().top + window.pageYOffset - 35;
            window.scrollTo({
                top: topPos,
                behavior: 'smooth'
            })
        }
    }

    closeExpand(){
        const expanded = document.getElementsByClassName("grid-expanded");
        if(expanded.length !== 0){
            ReactGA.pageview(this.props.location.pathname);
            
            expanded[0].classList.toggle("is-hidden");
            expanded[0].classList.toggle("grid-expanded");
            
            window.scrollTo({
                top: this.state.lastPos,
                behavior: 'smooth'
            })
        }
    }

    render() {
        let ShowContainer = "";
        let openingContainer = "";
        let shows = this.props.show;        
        if(this.props.minusOne !== undefined){
            shows = shows.slice(0,-1);
            openingContainer = <Grid item className='grid-container-item'>
                <Typography component={NavLink} to={"/show"} className="items-opening">ARCHIVES</Typography>
            </Grid>;
        }

        if(shows.length !== 0){
            ShowContainer = shows.map((item, idx)=>{
                let mixcloudkey = "";
                let playarchiveBtn = "";
                if(item.mixcloudurl !== null){
                    mixcloudkey = item.mixcloudurl.replace("https://www.mixcloud.com", "");
                    playarchiveBtn = <Button sx={{marginTop:"20px", marginBottom:"10px"}} variant="contained" color="green" startIcon={<PlayCircleOutlineIcon />} onClick={this.playArchives} data-mixcloudkey={mixcloudkey}>Play Archives</Button>
                }
                return(
                    <Fragment key={idx}>
                        <Grid onClick={this.expandGrid} data-type="artist" data-slug={item.slug} data-quick={idx} item className='grid-container-item' sx={{backgroundImage:"url("+encodeURI(item.image.fullurl)+")"}}></Grid>
                        <Box id={"show-expand-"+idx} className={"grid-container-item-section is-hidden artist expand-"+idx} data-expand={idx}>
                            <Box className="row50 cover-image-full" sx={{backgroundImage:"url("+encodeURI(item.image.fullurl)+")"}}></Box>
                            <Box className="row50" sx={{padding:{xs:"10px", md:"20px"}}}>
                                <Button color="dark" onClick={this.closeExpand} data-type="artist" data-quick={idx} sx={{position:"absolute", top:{xs:"5px",md:"15px"}, right:{xs:"5px",md:"15px"}}} startIcon={<CancelOutlinedIcon/>}>Close</Button>
                                <Typography sx={{fontSize:"22px", marginBottom:"5px"}}>#{item.episode}</Typography>
                                <Typography sx={{fontSize:"27px", lineHeight:"27px", fontWeight:"700", maxWidth:{xs:"100%", md:"80%"}, display:"block"}} component={NavLink} to={"/show/"+item.slug}>{item.title}</Typography>
                                <Hosts items={item.artists}/>
                                {playarchiveBtn}
                                {(item.description !== null)?<Typography sx={{whiteSpace:"break-spaces", marginTop:"20px"}}>{item.description}</Typography>:""}                                
                                <Genres items={item.genres}/>
                                <Tags items={item.tags}/>
                            </Box>
                        </Box>
                    </Fragment>
                )
            })
        }

        return (
            <>
                <Grid container spacing={0} className="grid-container">
                    {openingContainer}
                    {ShowContainer}
                </Grid>
                <Box className='archive-loadmore-container' sx={{width:"100%", borderTop:"1px solid #000"}}>
                    {this.props.showFullLoaded !== true?<Button sx={{width:"100%"}} onClick={this.loadMore}>Load More</Button>:""}
                </Box>
            </>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {        
        loadMoreShow: data => dispatch(loadMoreShow(data)),
        playMixcloud: data => dispatch(playMixcloud(data)),
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Shows));