import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
    //Link,
} from "react-router-dom";

import { getLibrary, addLibrary, updateLibrary, deleteLibrary } from "../../redux/actions/libraries";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AddCircle, Edit } from '@mui/icons-material';

// import Typography from '@mui/material/Typography';
// import Grid from '@mui/material/Grid';
// import Modal from '@mui/material/Modal';
// import TextField from '@mui/material/TextField';
//import { styled } from '@mui/material/styles';
//import Card from '@mui/material/Card';
//import Masonry from '@mui/lab/Masonry';

import ModalLibrary from '../../components/modals/library';

//import Box from '@mui/material/Box';
//import Icon from '@mui/material/Icon';

//<Paper elevation={0} />

class Library extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postPerPage:10,
            page:1,
            lastId:0,
            availableMore:true,
            libraries:[],
            isModalOpen:false,
            modalMode:"add",
            err:{},
            iditem:null,
            name:null,
            caption:null,
            file:null,
            isLoading:false
        };
        
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        this.onProgress = this.onProgress.bind(this);
        this.loadMoreLibrary = this.loadMoreLibrary.bind(this);
    }

    loadMoreLibrary(){
        this.props.getLibrary({slug:"all", page:this.state.page}).then((res) =>{
            if(res.data !== null){                
                this.setState({libraries:this.state.libraries.concat(res.data), page:this.state.page + 1, lastId:res.data[res.data.length - 1].id});
                if(res.data.length !== this.state.perpage){
                    this.setState({availableMore:false});
                }
            }else{
                this.setState({availableMore:false});
            }
        }).catch((err)=>{
            console.log("Something Error");
            console.log(err);
        })
    }

    onProgress(e){
        console.log(e);
        // this.setState({progressValue:})
    }

    handleChange(event){
        this.setState({[event.currentTarget.name]:event.currentTarget.value});
        if(event.currentTarget.files !== null && event.currentTarget.files !== undefined){
            this.setState({file:event.currentTarget.files[0]});
        }
    }

    handleDelete(e){
        e.preventDefault();
        this.setState({isLoading:true});
        this.props.deleteLibrary({id:this.state.iditem, onProgress:this.onProgress}).then((res)=>{
            if(res.code === 200){
                var joined = this.state.libraries;
                let obj = joined.findIndex(o => o.id === res.data.id);
                joined.splice(obj, 1);
                this.setState({ isLoading:false, libraries: joined }, function(){
                    this.closeModal();
                })
            }else{
                alert("Something Error");
            }
        })
    }

    handleUpdate(e){
        e.preventDefault();
        this.setState({isLoading:true});
        this.props.updateLibrary({id:this.state.iditem, name:this.state.name, caption:this.state.caption, onProgress:this.onProgress}).then((res)=>{
            if(res.code === 200){
                var joined = this.state.libraries;
                let obj = joined.findIndex(o => o.id === res.data.id);
                joined[obj] = res.data;
                this.setState({ isLoading:false, libraries: joined }, function(){
                    this.closeModal();
                })
            }else{
                alert("Something Error");
            }
        })
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({isLoading:true});
        this.props.addLibrary({name:this.state.name, caption:this.state.caption, file:this.state.file, onProgress:this.onProgress}).then((res)=>{
            if(res.code === 200){
                var joined = [res.data, ...this.state.libraries];
                this.setState({ isLoading:false, libraries: joined }, function(){
                    this.closeModal();
                })
            }else{
                alert("Something Error");
            }
        })
    }

    openModal(event){
        let dataiditem = parseInt(event.currentTarget.getAttribute('data-iditem'));
        let modalmode = event.currentTarget.getAttribute('modal-mode');
        
        if(isNaN(dataiditem) !== true){
            let obj = this.state.libraries.find(o => o.id === dataiditem);
            this.setState({iditem: obj.id, name:obj.name, caption:obj.caption}, function(){
                this.setState({isModalOpen:true, modalMode:modalmode});
            })
        }else{
            this.setState({name:null, caption:null, isModalOpen:true, modalMode:modalmode});            
        }
    }

    closeModal(){
        this.setState({
            isModalOpen:false,
            name:null,
            caption:null,
            file:null
        });
    }

    componentDidMount(){
        this.props.getLibrary({slug:"all", page:1, perpage: this.state.postPerPage}).then((res)=>{
            if(res.data !== null){
                this.setState({libraries:res.data, page:this.state.page + 1});       
            }else{
                this.setState({libraries:[], availableMore:false})
            }
        });
    }

    render() {
        const theme = createTheme();
        let libraryItem = <span>Nothing's found</span>;
        if(this.state.libraries.length > 0 ){
            libraryItem = this.state.libraries.map((item) => (
                <div key={item.id} className='library-grid-item'>
                    {(item.type === 'image')?<CardMedia component="img" image={item.fullurl} src={item.fullurl} sx={{ }}></CardMedia>:
                    <div className='library-grid-item-audio'><audio src={item.fullurl} controls={true}></audio><span>{retriveFile(item.url)}</span></div>}
                    <div className='library-grid-item-info'><Button variant="contained" endIcon={<Edit />} onClick={this.openModal} modal-mode="edit" data-iditem={item.id}> Edit </Button></div>
                </div>
            ));
        }

        function retriveFile(url){
            let filename = url.indexOf("/") + 1;
            return url.substr(filename);
        }

        return (
            <ThemeProvider theme={theme}>
            <Box sx={{ display: 'block' }}>
                <Container component="div" className='panel-section-container' maxWidth="xl">
                    <Box component="div" sx={{ display:'flex', alignItems:'right', justifyContent:'right', marginBottom:'10px'}}>
                        <Button variant="contained" endIcon={<AddCircle />} onClick={this.openModal} modal-mode="add"> Add Library </Button>
                    </Box>
                    <div className='library-grid-item-container'>
                        {libraryItem}                        
                    </div>
                    <Box sx={{
                        p: 1,
                        m: 1,
                        bgcolor: 'background.paper',
                        }}>
                        {(this.state.availableMore !== false)?<Button onClick={this.loadMoreLibrary} variant="contained" sx={{
                            margin:'auto',
                            display:'block'
                        }}>Load More</Button>:''}                
                    </Box>
                </Container>
            </Box>
            { (this.state.isModalOpen !== false)?<ModalLibrary isLoading={this.state.isLoading} name={this.state.name} caption={this.state.caption} modalMode={this.state.modalMode} isModalOpen={this.state.isModalOpen} closeModal={this.closeModal} handleChange={this.handleChange} handleDelete={this.handleDelete} handleUpdate={this.handleUpdate} handleSubmit={this.handleSubmit}/>:'' }
            </ThemeProvider>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getLibrary: libraryData => dispatch(getLibrary(libraryData)),
        addLibrary: libraryData => dispatch(addLibrary(libraryData)),
        updateLibrary: libraryData => dispatch(updateLibrary(libraryData)),
        deleteLibrary: libraryData => dispatch(deleteLibrary(libraryData)),        
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Library));