import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";

import { loadMoreArtist } from "../../redux/actions/artists";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

class Artists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      err: null,
    };
    this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    var ctx = this;
    var page = this.props.artist.length / process.env.REACT_APP_PERPAGE + 1;
    ctx.props.loadMoreArtist({ slug: "all", page: page, perpage: process.env.REACT_APP_PERPAGE }).then((res) => {});
  }

  render() {
    let artistContainer = "";
    let openingContainer = "";
    let artists = this.props.artist;
    if (this.props.minusOne !== undefined) {
      artists = artists.slice(0, -1);
      openingContainer = (
        <Grid item className="grid-container-item">
          <Typography component={NavLink} to={"/artist"} className="items-opening">
            ALL ARTISTS
          </Typography>
        </Grid>
      );
    }

    if (artists.length !== 0) {
      artistContainer = artists.map((item, idx) => {
        return (
          <Grid
            item
            key={idx}
            data-quick={idx}
            className="artist-container-item"
            component={NavLink}
            to={"/artist/" + item.slug}
            sx={{ backgroundImage: "url(" + encodeURI(item.image?.fullurl || process.env.REACT_APP_DEFAULT_AVATAR) + ") !important" }}
          >
            <Box className="artist-container-item-info">
              {/* <img alt={item.name} style={{display:"block", marginBottom:"10px", marginLeft:"auto", marginRight:"auto",}} src={`https://flagcdn.com/w20/${item.country.initial.toLowerCase()}.png`}/> */}
              <Typography
                sx={{
                  display: "block",
                  fontSize: "18px",
                  lineHeight: "18px",
                  fontWeight: "700",
                  marginBottom: "5px",
                  backgroundColor: "#000000b8",
                  color: "#ffffff",
                  padding: "10px",
                }}
              >
                {item.name}
              </Typography>
              <Typography
                sx={{
                  display: "block",
                  fontSize: "12px",
                  width: "fit-content",
                  marginLeft: "auto",
                  marginRight: "auto",
                  backgroundColor: "#000000b8",
                  color: "#ffffff",
                  padding: "5px",
                }}
              >
                {item.country.name}
              </Typography>
            </Box>
          </Grid>
        );
      });
    }

    return (
      <>
        <Grid container spacing={0} className="grid-container">
          {openingContainer}
          {artistContainer}
        </Grid>
        <Box className="archive-loadmore-container" sx={{ width: "100%", borderTop: "1px solid #000" }}>
          {this.props.artistFullLoaded !== true ? (
            <Button sx={{ width: "100%" }} onClick={this.loadMore}>
              Load More
            </Button>
          ) : (
            ""
          )}
        </Box>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadMoreArtist: (data) => dispatch(loadMoreArtist(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Artists));
