import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
} from "react-router-dom";

import { getGenre, addGenre, updateGenre, deleteGenre } from "../../redux/actions/genres";

import { Table, TableContainer, TableHead, TableRow, TableCell, tableCellClasses, Paper, TableBody, Button} from '@mui/material';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { AddCircle, Edit } from '@mui/icons-material';

import ModalGenre from '../../components/modals/genre';

class Genre extends Component {
    constructor(props) {
        super(props);
        this.state = {
            genres:[],
            postPerPage:10,
            page:1,
            lastId:0,
            availableMore:true,
            err:{},
            isModalOpen:false,
            name:null,
            caption:null,
            is_active:true,
            isLoading:false
        };

        this.loadMoreShow = this.loadMoreShow.bind(this);

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleDelete(e){
        e.preventDefault();
        this.setState({isLoading:true});
        this.props.deleteGenre({id:this.state.iditem}).then((res)=>{
            if(res.code === 200){
                var joined = this.state.genres;
                let obj = joined.findIndex(o => o.id === res.data.id);
                joined.splice(obj, 1);
                this.setState({ isLoading:false, genres: joined }, function(){
                    this.closeModal();
                })
            }else{
                alert("Something Error");
            }
        })
    }

    handleUpdate(e){
        e.preventDefault();
        this.setState({isLoading:true});
        this.props.updateGenre({id:this.state.iditem, name:this.state.name, is_active:this.state.is_active, caption:this.state.caption}).then((res)=>{
            if(res.code === 200){
                var joined = this.state.genres;
                let obj = joined.findIndex(o => o.id === res.data.id);
                joined[obj] = res.data;
                this.setState({ isLoading:false, genres: joined }, function(){
                    this.closeModal();
                })
            }else{
                alert("Something Error");
            }
        })
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({isLoading:true});
        this.props.addGenre({name:this.state.name, is_active:this.state.is_active, caption:this.state.caption}).then((res)=>{
            if(res.code === 200){
                var joined = [res.data, ...this.state.genres];
                this.setState({ isLoading:false, genres: joined }, function(){
                    this.closeModal();
                })
            }else{
                alert("Something Error");
            }
        })
    }

    handleChange(event){
        if(event.currentTarget.name !== "is_active"){
            this.setState({[event.currentTarget.name]:event.currentTarget.value});
        }else{
            let val = true;
            (event.currentTarget.value === "true")? val = false: val=true;
            this.setState({[event.currentTarget.name]:val});
        }
        //this.setState({[event.currentTarget.name]:event.currentTarget.value});
    }

    closeModal(){
        this.setState({
            isModalOpen:false,
            name:null,
            caption:null,
            is_active:true,
        });
    }

    openModal(event){
        let dataiditem = parseInt(event.currentTarget.getAttribute('data-iditem'));
        let modalmode = event.currentTarget.getAttribute('modal-mode');
        
        if(isNaN(dataiditem) !== true){
            let obj = this.state.genres.find(o => o.id === dataiditem);
            this.setState({iditem: obj.id, name:obj.name, is_active:obj.is_active, caption:obj.caption}, function(){
                this.setState({isModalOpen:true, modalMode:modalmode});
            })
        }else{
            this.setState({name:null, is_active:true, caption:null, isModalOpen:true, modalMode:modalmode});            
        }
    }

    loadMoreShow(){
        this.props.getGenre({slug:"all", page:this.state.page}).then((res) =>{
            if(res.data !== null){
                this.setState({genres:this.state.genres.concat(res.data), page:this.state.page + 1, lastId:res.data[res.data.length - 1].id});
                if(res.length < this.state.postPerPage){
                    this.setState({availableMore:false})
                }
                //if(res.data[res.data.length - 1].id !== this.state.lastId){}
            }else{
                this.setState({availableMore:false});
            }
        }).catch((err)=>{
            console.log("Something Error");
        })
    }

    componentDidMount(){
        this.props.getGenre({slug:"all", page:1, perpage: this.state.postPerPage}).then((res) =>{            
            if(res.data !== null){
                this.setState({genres:res.data, page:this.state.page + 1});  
                if(res.length < this.state.postPerPage){
                    this.setState({availableMore:false})
                }          
            }else{
                this.setState({genres:[], availableMore:false})
            }
        }).catch((err)=>{
            console.log("Something Error");
        })
    }

    render() {
        const theme = createTheme();
        const StyledTableCell = styled(TableCell)(({ theme }) => ({
            [`&.${tableCellClasses.head}`]: {
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
            },
            [`&.${tableCellClasses.body}`]: {
                fontSize: 14,
            },
        }));
        
        const StyledTableRow = styled(TableRow)(({ theme }) => ({
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
            '&:last-child td, &:last-child th': {
                border: 0,
            },
        }));

        return (
            <ThemeProvider theme={theme}>
            <Box sx={{ display: 'block' }}>
                <Container component="div" className='panel-section-container' maxWidth="xl">
                    <Box component="div" sx={{ display:'flex', alignItems:'right', justifyContent:'right', marginBottom:'10px'}}>
                        <Button variant="contained" endIcon={<AddCircle />} onClick={this.openModal}> Create Genre </Button>
                    </Box>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                            <TableRow>
                                <StyledTableCell>Title</StyledTableCell>
                                <StyledTableCell>Description</StyledTableCell>
                                <StyledTableCell align="center">Status</StyledTableCell>
                                <StyledTableCell align="center">Actions</StyledTableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.genres.map((row) => (
                                <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.name}</StyledTableCell>
                                <StyledTableCell>{row.caption}</StyledTableCell>
                                <StyledTableCell align="center">{row.is_active.toString()}</StyledTableCell>
                                <StyledTableCell align="center"><Button variant="contained" endIcon={<Edit />} onClick={this.openModal} modal-mode="edit" data-iditem={row.id}> Details </Button></StyledTableCell>
                                </StyledTableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{
                        p: 1,
                        m: 1,
                        bgcolor: 'background.paper',
                        }}>
                        {(this.state.availableMore !== false)?<Button onClick={this.loadMoreShow} variant="contained" sx={{
                            margin:'auto',
                            display:'block'
                        }}>Load More</Button>:''}                
                    </Box>
                </Container>
            </Box>
            {(this.state.isModalOpen === true)?<ModalGenre modalMode={this.state.modalMode} is_active={this.state.is_active} name={this.state.name} caption={this.state.caption} handleDelete={this.handleDelete} handleUpdate={this.handleUpdate} handleSubmit={this.handleSubmit} handleChange={this.handleChange} isModalOpen={this.state.isModalOpen} closeModal={this.closeModal}/>:""}
            </ThemeProvider>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getGenre: genreData => dispatch(getGenre(genreData)),
        addGenre: genreData => dispatch(addGenre(genreData)),
        updateGenre: tagData => dispatch(updateGenre(tagData)),
        deleteGenre: tagData => dispatch(deleteGenre(tagData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Genre));