import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    NavLink,
    withRouter,
} from "react-router-dom";

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

class Tags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:null
        };        
    }

    render() {
        var itemsContainer = "";
        var d = this.props.items;
        if(d.length !== 0){
            itemsContainer = 
            <Stack direction="row" spacing={1} sx={{marginTop:"10px"}}>
            <Typography>Tags : </Typography>
                {d.map((item, idx) =>{
                return(
                    <Chip key={idx} label={item.name} size="small" component={NavLink} to={"/tag/"+item.slug}/>
                )})}
            </Stack>
        }

        return (
            <Box>
                {itemsContainer}
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //logout: userData => dispatch(logout(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tags));