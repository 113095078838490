import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
    //Link,
} from "react-router-dom";

import { getCountries, getCountry } from "../../redux/actions/utils";
import { getLibrary } from "../../redux/actions/libraries";
import { getProgram } from "../../redux/actions/programs";
import { getArtist } from "../../redux/actions/artists";
import { getGenre } from "../../redux/actions/genres";
import { getTag } from "../../redux/actions/tags";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';

//toggel jouw!
//import FormControlLabel from '@mui/material/FormControlLabel';
/* <FormControlLabel
    control={<Switch checked={checked} onChange={handleChange} />}
    label="Show from target"
/> */
// import FormHelperText from '@mui/material/FormHelperText';
// import Switch from '@mui/material/Switch';

import { 
    subDays, 
    // format 
} from 'date-fns';//compareAsc
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import DateTimePicker from '@mui/lab/DateTimePicker';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { AddCircle, Delete } from '@mui/icons-material';

//npm i date-fns
//npm i @mui/lab
//const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'));

class ModalShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images:[],
            audios:[],
            artists:[],   
            programs:[],         
            tags:[],
            genres:[],
            err:{}
        };
        this.deleteItem = this.deleteItem.bind(this);
    }

    deleteItem(){
        if (window.confirm('Are you sure you want to save this thing into the database?')) {
            console.log('Thing was saved to the database.');
        } else {
            console.log('Thing was not saved to the database.');
        }
    }

    componentDidMount(){
        this.props.getArtist({}).then((res)=>{
            this.setState({artists:res.data});
        })

        this.props.getTag({}).then((res)=>{
            this.setState({tags:res.data});
        })

        this.props.getGenre({}).then((res)=>{
            this.setState({genres:res.data});
        })

        this.props.getProgram({}).then((res)=>{
            this.setState({programs:res.data});
        })

        this.props.getLibrary({slug:"all", type:"image", perpage:10000}).then((res)=>{
            this.setState({images:res.data});
        })

        this.props.getLibrary({slug:"all", type:"audio", perpage:10000}).then((res)=>{
            this.setState({audios:res.data});
        })
    }

    render() {
        let titlePopup = "Add Show";
        let deleteButton = "";
        let currentDate = Date.parse(this.props.show.scheduled_at);//format(this.props.show.scheduled_at, 'd MMMM yyyy h:mm aa');
        //let defaultImageval = null;

        switch(this.props.modalMode){
            case "edit":
                titlePopup = "Edit Show"
                deleteButton = <Button sx={{margin:'5px'}} variant="contained" endIcon={<Delete />} color="error" onClick={this.props.handleDelete}> Delete </Button>
                //console.log(this.props.show.idimage);
                //defaultImageval = null;//this.props.show.idimage;
                break;
            default:
                titlePopup = "Add Show";
                break;
        }

        return (
            <Modal open={this.props.isModalOpen} onClose={this.props.closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ display:'flex', alignItems:'center', justifyContent:'center'}}
            >
                <Box sx={{backgroundColor:"#fff", padding:"10px", borderRadius: "8px", minHeight:'280px', minWidth:'480px', maxWidth:'640px', maxHeight: '640px', overflowY: 'scroll'}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb:'10px', textAlign:'center'}}>{titlePopup}</Typography>
                    <Box component="form"
                        sx={{ '& .MuiTextField-root': { margin:'5px' } }}//width: 'calc(50% - 10px)' 
                        // noValidate
                        autoComplete="off"
                        onSubmit={(this.props.modalMode === "edit")?this.props.handleUpdate:this.props.handleSubmit}
                    >
                    <div>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} autoFocus={true} value={this.props.show.title || ""} required={true} id="input-title" label="Title" type="text" name="title" onChange={this.props.handleChange}/>
                    </div>
                    <div>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} value={this.props.show.episode || ""} required={true} id="input-episode" label="Episode" type="text" name="episode" onChange={this.props.handleChange}/>
                    </div>
                    <div>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} value={this.props.show.slug || ""} required={true} id="input-slug" label="Slug" type="text" name="slug" onChange={this.props.handleChange}/>
                    </div>
                    <div>
                        <DatePicker
                            selected={currentDate}
                            id="input-scheduled_at"
                            label="Schedule At"
                            type="text"
                            name="scheduled_at" 
                            minDate={subDays(new Date(), 5)}
                            onChange={(date) => {
                                this.props.handleMultipleChange(date, 'scheduled_at');
                            }}
                            showTimeSelect
                            timeFormat="HH:mm"
                            // injectTimes={[
                            //     setHours(setMinutes(new Date(), 1), 0),
                            //     setHours(setMinutes(new Date(), 5), 12),
                            //     setHours(setMinutes(new Date(), 59), 23),
                            // ]}
                            //value={this.props.show.scheduled_at || ""}
                            customInput={<TextField sx={{ width: 'calc(100% - 10px)' }} id="input-scheduled_at" label="Schedule at" type="text" name="scheduled_at" />}
                            dateFormat="d MMMM yyyy h:mm aa"
                        />
                    </div>
                    <div>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} 
                            id="input-description"
                            label="description"
                            name="description"
                            multiline
                            rows={3}
                            onChange={this.props.handleChange}
                            value={this.props.show.description || ""}
                        />
                    </div>
                    <div>
                    </div>
                    <div>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} value={this.props.show.soundcloudurl || ""} id="input-soundcloudurl" label="Soundcloud URL" type="text" name="soundcloudurl" onChange={this.props.handleChange}/>
                    </div>
                    <div>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} value={this.props.show.mixcloudurl || ""} id="input-mixcloudurl" label="Mixcloud URL" type="text" name="mixcloudurl" onChange={this.props.handleChange}/>
                    </div>
                    <div>
                        <Autocomplete
                            onChange={this.props.handleChange}                            
                            name="idaudio"
                            id="input-idaudio"
                            sx={{ width: 'calc(100% - 10px)' }}
                            options={this.state.audios}
                            autoHighlight
                            getOptionLabel={(option) => option.fullurl}
                            renderOption={(props, option) => (
                                <Box name="idaudio" component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.name}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <>
                                    <center>{(params.inputProps.value !== "")?<audio src={params.inputProps.value} height="50" controls={true} alt=""/>:""}</center>
                                    <TextField
                                    {...params}
                                    name="idaudio"
                                    label="Choose an audio"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div>
                        <Autocomplete
                            onChange={this.props.handleChange}                            
                            name="idprogram"
                            id="input-idprogram"
                            sx={{ width: 'calc(100% - 10px)' }}
                            options={this.state.programs}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option) => (
                                <Box name="idprogram" component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.name}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                name="idprogram"
                                label="Program"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                                />
                            )}
                        />
                    </div>
                    <div>
                        <Autocomplete
                            multiple
                            onChange={(event, newValue) => {
                                this.props.handleMultipleChange(newValue, 'artists');
                            }}
                            name="idartist"
                            id="input-idartist"
                            sx={{ width: 'calc(100% - 10px)' }}
                            options={this.state.artists}
                            getOptionLabel={(option) => option.name}
                            filterSelectedOptions
                            autoHighlight
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    data-multiple="true"
                                    label="Artists"
                                    placeholder="Artists"
                                />
                            )}
                        />
                    </div>
                    <div>
                        <Autocomplete
                            multiple
                            onChange={(event, newValue) => {
                                this.props.handleMultipleChange(newValue, 'genres');
                            }}
                            name="idgenre"
                            id="input-idgenre"
                            sx={{ width: 'calc(100% - 10px)' }}
                            options={this.state.genres}
                            getOptionLabel={(option) => option.name}
                            filterSelectedOptions
                            autoHighlight
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    data-multiple="true"
                                    label="Genre"
                                    placeholder="Genre"
                                />
                            )}
                        />
                    </div>
                    <div>
                        <Autocomplete
                            multiple
                            onChange={(event, newValue) => {
                                this.props.handleMultipleChange(newValue, 'tags');
                            }}
                            name="idatag"
                            id="input-idtag"
                            sx={{ width: 'calc(100% - 10px)' }}
                            options={this.state.tags}
                            getOptionLabel={(option) => option.name}
                            filterSelectedOptions
                            autoHighlight
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    data-multiple="true"
                                    label="Tag"
                                    placeholder="Tag"
                                />
                            )}
                        />
                    </div>
                    <div>
                        <Autocomplete
                            onChange={this.props.handleChange}                            
                            name="idimage"
                            id="input-idimage"
                            //value={defaultImageval || null}
                            sx={{ width: 'calc(100% - 10px)' }}
                            options={this.state.images}
                            autoHighlight
                            getOptionLabel={(option) => option.fullurl}
                            renderOption={(props, option) => (
                                <Box name="idimage" component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <img
                                    name="idimage"
                                    loading="lazy"
                                    height="50"
                                    src={`${option.fullurl}`}
                                    srcSet={`${option.fullurl}`}
                                    alt=""
                                />
                                {option.name}
                                </Box>
                            )}
                            renderInput={(params) => {
                                return <>
                                    {(params.inputProps.value !== "")?<center><img src={params.inputProps.value} height="50" alt=""/></center>:""}
                                    <TextField
                                    {...params}
                                    name="idimage"
                                    label="Choose an image"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                    />
                                </>
                            }}
                        />
                    </div>
                    <Box sx={{ display:'flex', alignItems:'right', justifyContent:'right'}}>
                        {deleteButton}
                        {(this.props.isLoading === true)?<Button sx={{margin:'5px'}} disabled={true} variant="contained" endIcon={<AddCircle />} type="submit"> Please Wait </Button>:<Button sx={{margin:'5px'}} variant="contained" endIcon={<AddCircle />} type="submit"> Submit </Button>}
                    </Box>
                    </Box>
                </Box>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCountries: artistData => dispatch(getCountries(artistData)),
        getCountry: artistData => dispatch(getCountry(artistData)),
        getLibrary: libraryData => dispatch(getLibrary(libraryData)),
        getProgram: libraryData => dispatch(getProgram(libraryData)),
        getArtist: libraryData => dispatch(getArtist(libraryData)),
        getGenre: libraryData => dispatch(getGenre(libraryData)),
        getTag: libraryData => dispatch(getTag(libraryData)),
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalShow));