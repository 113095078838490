import React, { Component } from "react";
import { connect } from "react-redux";
import ReactGA from "react-ga";

import MetaTags from "react-meta-tags";

import { withRouter } from "react-router-dom";

import { Box, Typography } from "@mui/material";

// import ComingSoonShop from '../assets/images/norrm-shop-banner.jpg'
import ShopSection from "../components/sections/shops";

class Shop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      err: null,
    };
  }

  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <>
        <MetaTags>
          {/* <title>Norrm Shop</title> */}
          <meta id="meta-description" name="description" content="Norrm Shop." />
          <meta id="og-title" property="og:title" content="Norrm Shop" />
        </MetaTags>
        <Box component="div" className="show-page" fullwidth="true">
          <Box>
            <Box className="archive-container">
              <Box className="section-container-header">
                <Typography>Shop</Typography>
              </Box>
            </Box>
            <ShopSection />
          </Box>
          {/* <Box>
                <Box className='shop-container'> */}
          {/* <img src={ComingSoonShop} width="100%" alt="norrm shop"/> */}
          {/* <Box className='section-container-header'>
                        <Typography>Press</Typography>
                    </Box>
                    <PressSection /> */}
          {/* </Box>
            </Box> */}
        </Box>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // getPress: data => dispatch(getPress(data)),
    // loadMorePress: data => dispatch(loadMorePress(data)),
    // login: userData => dispatch(login(userData)),
    // getArtist: data => dispatch(getArtist(data)),
    // getShow: data => dispatch(getShow(data))
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Shop));
