import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";

import jQuery from "jquery";

import { Link, withRouter } from "react-router-dom";

import { logout } from "../redux/actions/users";
import { playRadio, stopAudio, resetAudio, getNextShow, getCurrentShow, getStreamStatus } from "../redux/actions/utils";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Menu from "./menu";

import StopIcon from "@mui/icons-material/Stop";
import MenuIcon from "@mui/icons-material/Menu";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import NorrmLogoBlack from "../assets/images/norrm-logo-black.png";
// import NorrmLogoWhite from "../assets/images/norrm-logo-white.png";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastId: -1,
      mobileMenu: false,
      liveStream: false,
      playRadio: false,
      isLoading: false,
      playLabel: "Play",
      playLabel1: "Play",
      playLabel2: "Play",
      isPlay1: false,
      isPlay2: false,
      isLoading1: false,
      isLoading2: false,
      streamSrc: "https://listen.norrm.com/default",
      nowPlaying: "Now Playing : Norrm Radio 247 mixes", //Norrm Radio 247 mixes //Endless Mix - Golden Hour City Stroll
      currentShow: null,
      nextShow: null,
      err: null,
      dualMode: false,
    };

    this.logout = this.logout.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  logout() {
    let ctx = this;
    this.props.logout().then(function () {
      ctx.props.history.push("/");
    });
  }

  toggleMenu() {
    if (this.state.mobileMenu === false) {
      this.setState({ mobileMenu: true });
    } else {
      this.setState({ mobileMenu: false });
    }
  }

  componentDidMount() {
    if (window.innerWidth > 640) {
      var scrollOffset = jQuery(".main-header .logo-wrapper").outerHeight();
      var scrollOffsetAll = jQuery(".main-header").outerHeight();
      window.addEventListener("scroll", function (e) {
        if (window.pageYOffset >= scrollOffset) {
          jQuery(".main-header").css("position", "fixed");
          jQuery(".main-header").css("top", "-102.1px");
          jQuery(".body").css("margin-top", scrollOffsetAll + "px");
        } else {
          jQuery(".main-header").css("position", "relative");
          jQuery(".main-header").css("top", "0px");
          jQuery(".body").css("margin-top", "0px");
        }
      });
    } else {
      var scrollOffsetMobile = 70;
      var scrollOffsetMobileAll = 115;
      window.addEventListener("scroll", function (e) {
        if (window.pageYOffset >= scrollOffsetMobile) {
          jQuery(".main-header").css("position", "fixed");
          jQuery(".main-header").css("top", "-70.1px");
          jQuery(".body").css("margin-top", scrollOffsetMobileAll + "px");
        } else {
          jQuery(".main-header").css("position", "relative");
          jQuery(".main-header").css("top", "0px");
          jQuery(".body").css("margin-top", "0px");
        }
      });
    }

    var dualDate = new Date();
    if (dualDate.getMonth() === 7) {
      if (dualDate.getDate() === 3) {
        if (dualDate.getHours() >= 16 && dualDate.getHours() <= 20) {
          // console.log("Dual Mode");
          this.setState({ dualMode: true });
        } else {
          // console.log("End Show");
          this.getStatus();
          setInterval(() => {
            this.getStatus();
          }, 30000);
        }
      } else {
        this.getStatus();
        setInterval(() => {
          this.getStatus();
        }, 30000);
      }
    } else {
      this.getStatus();
      setInterval(() => {
        this.getStatus();
      }, 30000);
    }
  }

  getStatus() {
    var ctx = this;
    this.props.getCurrentShow().then(function (e) {
      if (e.data !== null) {
        if (ctx.state.lastId !== e.data.id) {
          ctx.setState(
            {
              lastId: e.data.id,
              currentShow: e.data,
              liveStream: true,
              nowPlaying: "Now Playing : " + e.data.title,
              streamSrc: e.data.is_stream !== false ? "https://listen.norrm.com/live" : "https://listen.norrm.com/stream",
            },
            function () {
              if (ctx.props.isAudioPlay === "radio") {
                ctx.props.resetAudio().then((e) => {
                  let audioPlayer = new Audio();
                  ctx.setState({ playLabel: "Loading..." }, function () {
                    audioPlayer.src = ctx.state.streamSrc;
                    audioPlayer.addEventListener("canplaythrough", function () {
                      ctx.props.playRadio({ audioPlayer: audioPlayer });
                      ctx.setState({ playLabel: "Play" });
                    });
                  });
                });
              }
            }
          );
        }
      } else {
        if (ctx.state.streamSrc !== "https://listen.norrm.com/default") {
          ctx.setState(
            {
              currentShow: null,
              liveStream: false,
              nowPlaying: "Now Playing : Norrm Radio 247 mixes", //Norrm Radio 247 mixes
              streamSrc: "https://listen.norrm.com/default",
            },
            function () {
              if (ctx.props.isAudioPlay === "radio") {
                ctx.props.resetAudio().then((e) => {
                  let audioPlayer = new Audio();
                  ctx.setState({ playLabel: "Loading..." }, function () {
                    audioPlayer.src = ctx.state.streamSrc;
                    audioPlayer.addEventListener("canplaythrough", function () {
                      ctx.props.playRadio({ audioPlayer: audioPlayer });
                      ctx.setState({ playLabel: "Play" });
                    });
                  });
                });
              }
            }
          );
        }
      }
    });

    this.props.getNextShow().then(function (e) {
      if (e.data !== null) {
        ctx.setState({ nextShow: e.data });
      } else {
        ctx.setState({ nextShow: null });
      }
    });
  }

  render() {
    var nextShowContainer = "";
    if (this.state.nextShow !== null) {
      nextShowContainer = (
        <Box component="marquee" sx={{ maxWidth: "400px", marginRight: "10px" }}>
          <Typography sx={{ marginRight: "5px", whiteSpace: "pre" }}>
            Upcoming Show : {this.state.nextShow.title} | {format(new Date(this.state.nextShow.scheduled_at.replace(/-/g, "/")), "dd / MMMM / yyyy")} |{" "}
            {format(new Date(this.state.nextShow.scheduled_at.replace(/-/g, "/")), "hh:mm aa")} -{" "}
            {format(new Date(this.state.nextShow.scheduled_end.replace(/-/g, "/")), "hh:mm aa")}
          </Typography>
        </Box>
      );
    } else {
      nextShowContainer = "";
    }

    return (
      <Box className="main-header">
        <Drawer
          anchor="left"
          open={this.state.mobileMenu}
          onClose={(e) => {
            this.setState({ mobileMenu: false });
          }}
        >
          <Menu closeMenu={this.toggleMenu} />
        </Drawer>
        <Box className="mobile-nav-link">
          <Button color="dark" onClick={this.toggleMenu}>
            <MenuIcon />
          </Button>
        </Box>
        <Box className="logo-wrapper">
          <Link to="/">
            <img alt="norrm logo" src={NorrmLogoBlack} />
          </Link>
        </Box>
        <Menu />
        {this.state.dualMode !== true ? (
          <Box
            sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px", backgroundColor: "#f8c41e", borderBottom: "1px solid #000" }}
          >
            {this.state.liveStream !== false ? (
              <Box
                sx={{
                  display: "flex",
                  width: "60px",
                  paddingLeft: "5px",
                  flexWrap: "nowrap",
                  flexDirection: "row",
                  alignItems: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <Box className="livestream-indicator"></Box>Live
              </Box>
            ) : (
              ""
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "5px",
                width: "fit-content",
                marginRight: "10px",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              {this.props.isAudioPlay !== "radio" ? (
                <Box
                  sx={{ display: "flex", alignItems: "center" }}
                  onClick={(e) => {
                    if (this.state.isLoading !== true) {
                      let ctx = this;
                      let audioPlayer = new Audio();
                      this.setState({ isLoading: true, playLabel: "Loading..." }, function () {
                        audioPlayer.src = ctx.state.streamSrc;
                        audioPlayer.addEventListener("error", () => {
                          alert("Something error, please try again");
                          ctx.setState({ playLabel: "Play", isLoading: false });
                        });

                        audioPlayer.addEventListener("canplaythrough", function () {
                          ctx.props.playRadio({ audioPlayer: audioPlayer });
                          ctx.setState({ playLabel: "Play", isLoading: false });
                        });
                        audioPlayer.load();
                      });
                    }
                  }}
                >
                  <PlayArrowIcon sx={{ marginRight: "5px" }} /> {this.state.playLabel}
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={(e) => {
                    this.props.stopAudio();
                  }}
                >
                  <StopIcon sx={{ marginRight: "5px" }} /> Stop
                </Box>
              )}
            </Box>
            <Box component="marquee" sx={{ width: "-webkit-fill-available", marginRight: "10px" }}>
              {this.state.nowPlaying}
            </Box>
            {nextShowContainer}
          </Box>
        ) : (
          <Box
            className="dualmode-container"
            sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px", backgroundColor: "#f8c41e", borderBottom: "1px solid #000" }}
          >
            <Box className="dualmode-status">
              <Box
                sx={{
                  display: "flex",
                  width: "60px",
                  paddingLeft: "5px",
                  flexWrap: "nowrap",
                  flexDirection: "row",
                  alignItems: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <Box className="livestream-indicator"></Box>Live
              </Box>
              {this.state.isPlay1 === true ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={async (e) => {
                    await this.props.resetAudio();

                    // console.log("Stop");
                    this.props.stopAudio();
                    this.setState({ isPlay1: false, playLabel1: "Play" });
                  }}
                >
                  <StopIcon sx={{ marginRight: "5px" }} /> Stop
                </Box>
              ) : (
                <Box
                  sx={{ display: "flex", alignItems: "center", marginRight: "5px", cursor: "pointer" }}
                  onClick={(e) => {
                    if (this.state.isLoading !== true) {
                      if (this.state.isPlay2 === true) {
                        this.props.stopAudio();
                        this.setState({ playLabel2: "Play", isPlay2: false });
                      }

                      let ctx = this;
                      let audioPlayer1 = new Audio();
                      this.setState({ isLoading: true, playLabel1: "Loading..." }, function () {
                        audioPlayer1.src = "https://listen.norrm.com/live";
                        audioPlayer1.addEventListener("error", (e) => {
                          // console.log(e);
                          alert("Something error, please try again");
                          ctx.setState({ playLabel1: "Play", isLoading: false });
                        });

                        audioPlayer1.addEventListener("canplaythrough", function () {
                          ctx.props.playRadio({ audioPlayer: audioPlayer1 });
                          ctx.setState({ playLabel1: "Play", isLoading: false, isPlay1: true });
                        });
                        audioPlayer1.load();
                      });
                    }
                  }}
                >
                  <PlayArrowIcon sx={{ marginRight: "5px" }} /> {this.state.playLabel1}
                </Box>
              )}
              <Box component="marquee" sx={{ width: "-webkit-fill-available", marginRight: "10px" }}>
                Norrm Radio Special - Live from Keuken Festival @ Posco Bandung
              </Box>
            </Box>

            <Box className="dualmode-status bottom">
              <Box
                sx={{
                  display: "flex",
                  width: "60px",
                  paddingLeft: "5px",
                  flexWrap: "nowrap",
                  flexDirection: "row",
                  alignItems: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <Box className="livestream-indicator"></Box>Live
              </Box>
              {this.state.isPlay2 === true ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={async (e) => {
                    await this.props.resetAudio();
                    // this.props.stopAudio();
                    // this.setState({ playLabel1: "Play", isPlay1: false });

                    // console.log("Stop");
                    this.props.stopAudio();
                    this.setState({ isPlay2: false, playLabel2: "Play" });
                  }}
                >
                  <StopIcon sx={{ marginRight: "5px" }} /> Stop
                </Box>
              ) : (
                <Box
                  sx={{ display: "flex", alignItems: "center", marginRight: "5px", cursor: "pointer" }}
                  onClick={async (e) => {
                    if (this.state.isLoading !== true) {
                      if (this.state.isPlay1 === true) {
                        await this.props.resetAudio();
                        this.props.stopAudio();
                        this.setState({ playLabel1: "Play", isPlay1: false });
                      }

                      let ctx = this;
                      let audioPlayer2 = new Audio();
                      this.setState({ isLoading: true, playLabel2: "Loading..." }, function () {
                        audioPlayer2.src = "https://listen.norrm.com/live2";
                        audioPlayer2.addEventListener("error", (e) => {
                          // console.log(e);
                          alert("Something error, please try again");
                          ctx.setState({ playLabel2: "Play", isLoading: false });
                        });

                        audioPlayer2.addEventListener("canplaythrough", function () {
                          ctx.props.playRadio({ audioPlayer: audioPlayer2 });
                          ctx.setState({ playLabel2: "Play", isLoading: false, isPlay2: true });
                        });
                        audioPlayer2.load();
                      });
                    }
                  }}
                >
                  <PlayArrowIcon sx={{ marginRight: "5px" }} /> {this.state.playLabel2}
                </Box>
              )}
              <Box component="marquee" sx={{ width: "-webkit-fill-available", marginRight: "10px" }}>
                Norrm Radio Special - Live from 3rd Anniversary Photograms @ Grammars
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLogin: state.isLogin,
    userData: state.userData,
    isAudioPlay: state.isAudioPlay,
    audioPlayer: state.audioPlayer,
    // isLiveStreaming: state.isLiveStreaming,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
      getNextShow,
      getCurrentShow,
      playRadio,
      stopAudio,
      resetAudio,
      getStreamStatus,
    },
    dispatch
  );
}

// const mapStateToProps = state => ({
//     ...state
// });

// function mapDispatchToProps(dispatch) {
//     return {
//         logout
//         //logout: userData => dispatch(logout(userData))
//     };
// }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
