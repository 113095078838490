import axios from 'axios';
import {BASE_URL, BASE_VERSION} from "../config";

export const loadMoreArtist = (payload) => {
  return (dispatch) => {
    var perpage = payload.perpage;

    if(payload.slug === undefined || payload.slug === null){
      payload.slug = "all";
    }

    let paramPage = '?';
    if(payload.page !== undefined){
      paramPage += 'page='+payload.page;
    }
    if(perpage !== undefined){
      if(payload.page !== undefined){
        paramPage += '&perpage='+perpage;
      }else{
        paramPage += 'perpage='+perpage;
      }
    }else{
      paramPage += 'perpage='+process.env.REACT_APP_PERPAGE;
    }

    return( axios.get(BASE_URL+BASE_VERSION+'artist/'+payload.slug+paramPage, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        dispatch({ type: 'APPEND_ARTIST', payload })
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const getArtist = (payload) => {
  return (dispatch) => {
    if(payload.slug === undefined || payload.slug === null){
      payload.slug = "all";
    }

    let paramPage = '?';
    if(payload.page !== undefined){
      paramPage += 'page='+payload.page;
    }
    if(payload.perpage !== undefined){
      if(payload.page !== undefined){
        paramPage += '&perpage='+payload.perpage;
      }else{
        paramPage += 'perpage='+payload.perpage;
      }
    }

    return( axios.get(BASE_URL+BASE_VERSION+'artist/'+payload.slug+paramPage, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        dispatch({ type: 'SET_ARTIST', payload })
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const getArtistDetails = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'artist/'+payload.slug, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const addArtist = (payload) => {
  return (dispatch) => {
    return( axios.post(BASE_URL+BASE_VERSION+'artist', {
      name: payload.artist.name,
      slug: payload.artist.slug,
      description: payload.artist.description,
      weburl: payload.artist.weburl,
      instagramurl: payload.artist.instagramurl,
      mixcloudurl: payload.artist.mixcloudurl,
      soundcloudurl: payload.artist.soundcloudurl,
      idcountry: payload.artist.idcountry,
      idimage: payload.artist.idimage
    }, {
      headers: { 
        Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
      }, validateStatus: () => true
    })
    .then((response) => {
      payload = response.data;
      if(response.data.code === 200){
        dispatch({ type: 'SET_LOGIN', payload })
      }
      return payload;
    })
    .catch((error) => {
      console.log(error);
      return error;
    })
    )
  }
}

export const updateArtist = (payload) => {
  return (dispatch) => {
    return( axios.put(BASE_URL+BASE_VERSION+'artist/'+payload.id, 
      {
        name: payload.artist.name,
        slug: payload.artist.slug,
        description: payload.artist.description,
        weburl: payload.artist.weburl,
        instagramurl: payload.artist.instagramurl,
        mixcloudurl: payload.artist.mixcloudurl,
        soundcloudurl: payload.artist.soundcloudurl,
        idcountry: payload.artist.idcountry,
        idimage: payload.artist.idimage
      }, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        },
        validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const deleteArtist = (payload) => {
  return (dispatch) => {
    return( axios.delete(BASE_URL+BASE_VERSION+'artist/'+payload.id,
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        },
        validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}