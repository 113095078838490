import axios from 'axios';
import {BASE_URL, BASE_VERSION} from "../config";

export const getSlideshow = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'slideshow/'+payload.slug, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        dispatch({ type: 'SET_SLIDESHOW', payload })
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const getMobileSlideshow = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'slideshow_mobile/'+payload.slug, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        dispatch({ type: 'SET_MOBILE_SLIDESHOW', payload })
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}