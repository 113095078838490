import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
    NavLink
    //Link,
} from "react-router-dom";

//import Box from '@mui/material/Box';
//import Icon from '@mui/material/Icon';

class Sidemenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:{}
        };
        
        //this.onLogout = this.onLogout.bind(this);
    }

    // onLogout(params) {
        
    // }

    componentDidMount(){
        //console.log('header mounted');
    }

    render() {
        const url = this.props.match.url;

        return (
            <div className='side-menu'>
                <ul>
                    <li><NavLink to={`${url}`} activeClassName="active">Home</NavLink></li>
                    {/* <li><NavLink to={`${url}/schedule`} activeClassName="Shows">Schedule</NavLink></li> */}
                    <li><NavLink to={`${url}/shows`} activeClassName="Shows">Shows</NavLink></li>
                    <li><NavLink to={`${url}/programs`} activeClassName="active">Programs</NavLink></li>
                    <li><NavLink to={`${url}/artists`} activeClassName="active">Artists</NavLink></li>
                    <li><NavLink to={`${url}/genres`} activeClassName="active">Genres</NavLink></li>
                    <li><NavLink to={`${url}/tags`} activeClassName="active">Tags</NavLink></li>
                    <li><NavLink to={`${url}/library`} activeClassName="active">Library</NavLink></li>
                    {/* <li><NavLink to={`${url}/images`} activeClassName="active">Images Library</NavLink></li>
                    <li><NavLink to={`${url}/audios`} activeClassName="active">Audios Library</NavLink></li> */}
                    {/* <li><NavLink to={`${url}/press`} activeClassName="active">Press</NavLink></li>
                    <li><NavLink to={`${url}/press-categories`} activeClassName="active">Press Categories</NavLink></li>
                    <li><NavLink to={`${url}/press-tags`} activeClassName="active">Press Tags</NavLink></li> */}
                    {/* <li><NavLink to={`${url}/sliders`} activeClassName="active">Sliders</NavLink></li> */}
                    {/* <li><NavLink to={`${url}/settings`} activeClassName="active">Settings</NavLink></li> */}
                </ul>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //logout: userData => dispatch(logout(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidemenu));