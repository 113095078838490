import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import ReactGA from 'react-ga';

import {
    NavLink,
    withRouter
} from "react-router-dom";

import { getProgramDetails } from "../redux/actions/programs";

import { 
    Box, 
    Grid, 
    Typography 
} from '@mui/material';

import LoadingSection from '../components/sections/loadings';

class ProgramDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shows:[],
            loaded:false,
            program:null,
            err:null
        };
    }

    componentDidMount(){
        ReactGA.pageview(this.props.location.pathname);

        this.props.getProgramDetails({slug:this.props.match.params.slug}).then((res)=>{
            if(res.code === 200){            
                this.setState({program:res.data, loaded:true, shows:res.shows});
            }
        })
    }

    render() {
        let ShowContainer = "";
        let shows = this.state.shows;
        if(shows !== undefined && shows.length !== 0){            
            ShowContainer = shows.map((item, idx)=>{                
                return(
                    <Fragment key={idx}>
                        <Grid component={NavLink} to={"/show/"+item.slug} data-type="artist" data-quick={idx} item className='grid-container-item' sx={{backgroundImage:"url("+encodeURI(item.image.fullurl)+")"}}></Grid>                    
                    </Fragment>
                )
            })
        }

        return (
            <Box component="div" className="program-detail-page">
                {(this.state.loaded !== false)?
                    (this.state.program !== null)?
                    <Box sx={{ minHeight:"450px" }} className="program-container">
                        {(this.state.program.image !== null)?
                            <Box sx={{
                                display:"block",
                                minHeight:"450px",
                                borderBottom:"1px solid #000000",
                                backgroundSize:"cover",
                                backgroundPosition:"center",
                                backgroundImage:"url("+encodeURI(this.state.program.image?.fullurl)+")",
                            }}></Box>:""
                        }

                        <Box sx={{ 
                            padding:"30px",                         
                            textAlign:"center",
                            borderBottom:"1px solid #000000",
                        }}>
                            <Typography sx={{fontSize:"24px", fontWeight:"800"}}>{this.state.program.name}</Typography>
                            {this.state.program.description!==null?<Typography sx={{marginTop:"20px"}}>{this.state.program.description}</Typography>:""}                        
                        </Box>
                        
                        <Grid container spacing={0} className="grid-container">
                            {ShowContainer}
                        </Grid>
                    </Box>
                    :"Program Not Found"
                :<LoadingSection />}
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProgramDetails: data => dispatch(getProgramDetails(data)),
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProgramDetail));