import React, { Component } from "react";
import { connect } from "react-redux";

import ReactGA from "react-ga";

import { login, checkLogin } from "./redux/actions/users";
import { getShow } from "./redux/actions/shows";
import { getTag } from "./redux/actions/tags";
import { getGenre } from "./redux/actions/genres";
import { getPress } from "./redux/actions/presses";
import { getArtist } from "./redux/actions/artists";
import { getProgram } from "./redux/actions/programs";
import { getProduct } from "./redux/actions/shops";
import { getSchedules } from "./redux/actions/utils";
import { getSlideshow, getMobileSlideshow } from "./redux/actions/slideshows";

import Box from "@mui/material/Box";

import Header from "./components/header";
import Footer from "./components/footer";
import ScrollToTop from "./components/scrollcontrol";

import Login from "./pages/login";
import Profile from "./pages/profile";

import Home from "./pages/home";
import Press from "./pages/press";
import Show from "./pages/show";
import Bar from "./pages/bar";
import Shop from "./pages/shop";
import ShowDetail from "./pages/showDetail";
import Artist from "./pages/artist";
import ArtistDetails from "./pages/artistDetail";
import Program from "./pages/program";
import ProgramDetail from "./pages/programDetail";
import Genre from "./pages/genre";
import GenreDetail from "./pages/genreDetail";
import Tag from "./pages/tag";
import TagDetail from "./pages/tagDetail";
import About from "./pages/about";
import Live from "./pages/live";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import "./App.css";
import "./AppMobile.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductDetail from "./pages/productDetail";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
      isLoaded: false,
      from: "/profile",
      err: {
        message: "",
        code: 0,
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isLogin !== this.state.isLogin) {
      this.setState({ isLogin: this.props.isLogin });
    }
  }

  componentDidMount() {
    ReactGA.initialize("UA-45733690-21");
    if (this.props.slideshow.length === 0) {
      this.props.getSlideshow({ slug: "all" }).then((res) => {});
    }

    if (this.props.mobileSlideshow.length === 0) {
      this.props.getMobileSlideshow({ slug: "all" }).then((res) => {});
    }

    if (this.props.schedule.length === 0) {
      this.props.getSchedules().then((res) => {});
    }

    if (this.props.show.length === 0) {
      this.props.getShow({ slug: "all", perpage: process.env.REACT_APP_PERPAGE }).then((res) => {});
    }

    if (this.props.artist.length === 0) {
      this.props.getArtist({ slug: "all", perpage: process.env.REACT_APP_PERPAGE }).then((res) => {});
    }

    if (this.props.program.length === 0) {
      this.props.getProgram({ slug: "all", perpage: process.env.REACT_APP_PERPAGE }).then((res) => {});
    }

    if (this.props.product.length === 0) {
      this.props.getProduct({ slug: "all", perpage: process.env.REACT_APP_PERPAGE }).then((res) => {});
    }

    if (this.props.press.length === 0) {
      this.props.getPress({ slug: "all", perpage: process.env.REACT_APP_PERPAGE }).then((res) => {});
    }

    if (this.props.genre.length === 0) {
      this.props.getGenre({ slug: "all", perpage: process.env.REACT_APP_PERPAGE }).then((res) => {});
    }

    if (this.props.tag.length === 0) {
      this.props.getTag({ slug: "all", perpage: process.env.REACT_APP_PERPAGE }).then((res) => {});
    }
  }

  render() {
    const theme = createTheme({
      palette: {
        dark: {
          main: "#000000",
          dark: "#000000",
          light: "#000000",
          contrastText: "#ffffff",
        },
        light: {
          main: "#ffffff",
          dark: "#ffffff",
          light: "#ffffff",
          contrastText: "#000000",
        },
        green: {
          main: "#46c100",
          dark: "#2c7900",
          light: "#2c7900",
          contrastText: "#ffffff",
        },
      },
    });

    var mixcloudPlayer = "none";
    if (this.props.isAudioPlay === "mixcloud") {
      mixcloudPlayer = "block";
    }

    return (
      <ThemeProvider theme={theme}>
        <Box>
          <CssBaseline />
          <Router basename="/">
            <ScrollToTop>
              {this.state.isLogin === true ? <Redirect to={this.state.from}></Redirect> : ""}
              <Header />
              <Box className="body">
                <Switch>
                  <PrivateRoute loginState={this.state.isLogin} path="/profile">
                    <Profile />
                  </PrivateRoute>

                  <Route exact path="/login">
                    <Login />
                  </Route>

                  <Route path="/show/:slug">
                    <ShowDetail />
                  </Route>
                  <Route exact path="/show">
                    <Show />
                  </Route>

                  <Route path="/program/:slug">
                    <ProgramDetail />
                  </Route>
                  <Route exact path="/program">
                    <Program />
                  </Route>

                  <Route path="/press/:slug">
                    <Press />
                  </Route>
                  <Route exact path="/press">
                    <Press />
                  </Route>

                  <Route path="/artist/:slug">
                    <ArtistDetails />
                  </Route>
                  <Route exact path="/artist">
                    <Artist />
                  </Route>

                  <Route path="/tag/:slug">
                    <TagDetail />
                  </Route>
                  <Route exact path="/tag">
                    <Tag />
                  </Route>

                  <Route path="/genre/:slug">
                    <GenreDetail />
                  </Route>
                  <Route exact path="/genre">
                    <Genre />
                  </Route>

                  <Route path="/product/:slug">
                    <ProductDetail />
                  </Route>
                  <Route exact path="/shop">
                    <Shop />
                  </Route>

                  <Route exact path="/radiobar">
                    <Bar />
                  </Route>

                  <Route exact path="/about">
                    <About />
                  </Route>

                  <Route exact path="/live">
                    <Live />
                  </Route>

                  <Route exact path="/">
                    <Home />
                  </Route>
                </Switch>
              </Box>

              <Footer />

              <Box
                sx={{
                  display: mixcloudPlayer,
                  position: "fixed",
                  bottom: "0px",
                  height: "60px",
                  width: "100vw",
                  backgroundColor: "#000000",
                  color: "#ffffff",
                  alignItems: "center",
                }}
              >
                <Box id="iframe-container-mixcloud"></Box>
              </Box>
            </ScrollToTop>
          </Router>
        </Box>
      </ThemeProvider>
    );
  }
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        rest.loginState !== false ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

function mapDispatchToProps(dispatch) {
  return {
    login: (data) => dispatch(login(data)),
    checkLogin: (data) => dispatch(checkLogin(data)),
    getSlideshow: (data) => dispatch(getSlideshow(data)),
    getMobileSlideshow: (data) => dispatch(getMobileSlideshow(data)),
    getSchedules: (data) => dispatch(getSchedules(data)),
    getProgram: (data) => dispatch(getProgram(data)),
    getProduct: (data) => dispatch(getProduct(data)),
    getArtist: (data) => dispatch(getArtist(data)),
    getPress: (data) => dispatch(getPress(data)),
    getShow: (data) => dispatch(getShow(data)),
    getGenre: (data) => dispatch(getGenre(data)),
    getTag: (data) => dispatch(getTag(data)),
  };
}

//--openssl-legacy-provider

export default connect(mapStateToProps, mapDispatchToProps)(App);