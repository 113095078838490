import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
} from "react-router-dom";

import { format } from "date-fns";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

class Schedules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:null
        };        
    }

    render() {  
        let scheduleContainer = "";
        let schedules = this.props.schedule;
        
        function checkArr(arr, data){
            let o = arr.findIndex(o => o.title === data.scheduled_at.substring(0, 10));
            return o;
        }

        if(schedules.length !== 0){
            var dates = [];
            for(var i=0; i<schedules.length; i++){
                if(checkArr(dates, schedules[i]) === -1){
                    dates.push({
                        title:schedules[i].scheduled_at.substring(0, 10),
                        data:[]
                    });
                }
            }
            for(let i = 0; i<schedules.length; i++){
                dates[checkArr(dates, schedules[i])].data.push(schedules[i]);
            }

            scheduleContainer = dates.map((item, idx)=>{
                return(
                    <Grid item xs={12} key={idx} data-quick={idx} className='schedule-container-item'>
                        <Box 
                            sx={{ 
                                borderBottom:"1px solid #929292", 
                                paddingBottom:"5px", 
                                fontWeight:"900",
                                fontSize:"18px"
                            }}
                        >
                            {format(new Date(item.title.replace(/-/g, "/")), "dd / MMMM / yyyy")}
                        </Box>
                        <Box>
                            {item.data.map((item, idx)=>(
                                <Box key={idx}
                                    className="schedule-show-item"
                                    sx={{
                                        borderBottom:"1px solid #202020", 
                                        paddingTop:"5px", 
                                        paddingBottom:"5px",
                                        display:"flex",
                                        alignItems:"center",
                                        flexDirection:"row",
                                        flexWrap:"wrap",
                                        justifyContent:"flex-start",
                                        alignContent:"center"
                                    }}
                                >
                                    <Typography sx={{ width:{xs:"50%", md:"250px"} }}>{format(new Date(item.scheduled_at.replace(/-/g, "/")), "hh:mm aa")} - {format(new Date(item.scheduled_end.replace(/-/g, "/")), "hh:mm aa")}</Typography>
                                    <Typography sx={{ width:{xs:"50%", md:"calc(50% - 250px)"} }}>{item.title}</Typography>
                                </Box>
                            ))}
                        </Box>
                    </Grid>
                )
            })
        }

        return (
            <Box sx={{display:schedules.length !== 0?"block":"none"}}>
                <Box className='schedule-container'>
                    <Box className='section-container-header'>
                        <Typography sx={{fontWeight:"700", fontSize:"20px", padding:"8px"}}>Upcoming Show</Typography>
                    </Box>
                    <Grid container spacing={0}>
                        {scheduleContainer}
                    </Grid>
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //logout: userData => dispatch(logout(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Schedules));