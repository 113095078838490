import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
    //Link,
} from "react-router-dom";

//import Box from '@mui/material/Box';
//import Icon from '@mui/material/Icon';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
//import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';

import { createTheme, ThemeProvider } from '@mui/material/styles';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:{}
        };
        
        //this.onLogout = this.onLogout.bind(this);
    }

    // onLogout(params) {
        
    // }

    componentDidMount(){
        //console.log('header mounted');
    }

    render() {
        const theme = createTheme();

        return (
            <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
            <Container component="div" className='panel-section-container'>
                <CssBaseline />
                <Grid container spacing={2}>                    
                    <Grid item xs={4}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography>All Shows</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={8}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography>Programs</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography>Schedule</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            </Box>
            </ThemeProvider>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //logout: userData => dispatch(logout(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));