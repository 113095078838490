import axios from 'axios';
import {BASE_URL, BASE_VERSION, STATUS_URL} from "../config";

export const playRadio = (payload) => {
  return (dispatch) => {
    dispatch({ type: 'PLAY_RADIO', payload });
    var doc = document.getElementById("iframe-container-mixcloud");
    doc.innerHTML = "";
    try{
      if ('mediaSession' in navigator) {
        navigator.mediaSession.metadata = new window.MediaMetadata({
          title: '247 Mixes',
          artist: 'Norrm Radio',
          // album: 'The Ultimate Collection (Remastered)',
          artwork: [
            { src: 'https://norrm.s3.ap-southeast-1.amazonaws.com/library/norrm-icon-bg.png', sizes: '512x512', type: 'image/png' }
          ]
        })
      }
    }catch(e){
      console.log("error media session")
    }
  }
}

export const stopAudio = (...payload) => {
  return async (dispatch) => {
      await dispatch({ type: 'STOP_AUDIO', payload });
      var doc = document.getElementById("iframe-container-mixcloud");
      doc.innerHTML = "";
  }
}

export const resetAudio = (...payload) => {
  return async (dispatch) => {
    await dispatch({ type: 'RESET_AUDIO', payload});
      var doc = document.getElementById("iframe-container-mixcloud");
      doc.innerHTML = "";
  }
}

export const playMixcloud = (payload) => {
  return (dispatch) => {
    dispatch({ type: 'PLAY_MIXCLOUD', payload })
    var feed = payload.key;//"/norrm/nr440-tea-time-with-montana-hosted-by-fujis-bazaar/";//"/norrm/norrm-radio-listen-with-merdi-simanjuntak/";
    var doc = document.getElementById("iframe-container-mixcloud");
    doc.innerHTML = '<iframe src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&light=0&autoplay=true&feed='+feed+'" allow="autoplay" height="60" width="100%" frameborder="0" scrolling="no" id="mixcloud-widget-iframe"></iframe><div class="btn-close-player noselect">Close</div>';

    // var widget = window.Mixcloud.PlayerWidget(document.getElementById("mixcloud-widget-iframe"));
    // widget.ready.then(function() {
    //   widget.play();
    // });

    var closeBtnMix = document.querySelector(".btn-close-player");
    closeBtnMix.addEventListener("click", function(e){
      // widget.pause();
      dispatch({ type: 'STOP_AUDIO', payload });
      doc.innerHTML = "";
    })

    // doc.appendChild(<iframe src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&light=1&autoplay=true&feed=/norrm/norrm-radio-listen-with-merdi-simanjuntak/" allow="autoplay" height="60" width="100%" frameborder="0" scrolling="no" id="myFrame"></iframe>);
    // var widget = window.Mixcloud.PlayerWidget(document.getElementById("my-widget-iframe"));
    // widget.ready.then(function() {
    //   widget.load("/norrm/norrm-radio-listen-with-merdi-simanjuntak/", true);
    //   // widget.play();
    //   // Put code that interacts with the widget here
    // });
  }
}

export const getNextShow = (...payload) => {
  return (dispatch) => {
    return(
      axios.get(BASE_URL+BASE_VERSION+'nextshow')
      .then((response) => {
        payload = response.data;
        return payload;
      }).catch((error) => {
        console.log(error);
        return error;
      })
    )
  }
}
export const getCurrentShow = (...payload) => {
  return (dispatch) => {
    return( 
      axios.get(BASE_URL+BASE_VERSION+'currentshow')
      .then((response) => {
        payload = response.data;
        return payload;
      }).catch((error) => {
        console.log(error);
        return error;
      })
    )
  }
}

export const getStreamStatus = (...payload) => {
  return (dispatch) => {
    return( axios.get(STATUS_URL)
    .then((response) => {
      payload = response.data;
      return payload;
    })
    .catch((error) => {
      console.log(error);
      return error;
    })
    )
  }
}

export const getSchedules = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'schedule' )
    .then((response) => {
      payload = response.data;
      dispatch({ type: 'SET_SCHEDULE', payload })
      return payload;
    })
    .catch((error) => {
      console.log(error);
      return error;
    })
    )
  }
}

export const getCountries = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'country/all')
    .then((response) => {
      payload = response.data;
      return payload;
    })
    .catch((error) => {
      console.log(error);
      return error;
    })
    )
  }
}

export const getCountry = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'country/'+payload.id)
    .then((response) => {
      payload = response.data;
      return payload;
    })
    .catch((error) => {
      console.log(error);
      return error;
    })
    )
  }
}