import React, { Component } from 'react';
import { connect } from "react-redux";
import ReactGA from 'react-ga';

import {
    // NavLink,
    withRouter
} from "react-router-dom";

import { getProductDetails } from "../redux/actions/shops";
// import { playMixcloud } from "../redux/actions/utils";

import Chip from '@mui/material/Chip';

import { 
    Box,
    Button,
    Typography 
} from '@mui/material';

// import Tags from '../components/stacks/tags';
// import Hosts from '../components/stacks/hosts';
// import Genres from '../components/stacks/genres';

// import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import LoadingSection from '../components/sections/loadings';

class ProductDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded:false,
            product:null,
            err:null
        };
    }

    componentDidMount(){
        ReactGA.pageview(this.props.location.pathname);
        this.props.getProductDetails({slug:this.props.match.params.slug}).then((res)=>{
            if(res.code === 200){
                this.setState({product:res.data[0], loaded:true});
            }
        })
    }

    render() {
        var item = this.state.product;
        var productDetailContainer = "";

        if(this.state.product !== null){
            productDetailContainer = 
                <Box className={"grid-container-item-section show"} sx={{display:"grid", height:"auto", gridTemplateColumns:"repeat(auto-fit, 50%)"}}>
                    <Box className="row50 cover-image-full" sx={{backgroundImage:"url("+encodeURI(item.image[0]?.url)+")"}}></Box>
                    <Box className="row50" sx={{padding:{xs:"10px", md:"20px"}}}>
                        <Typography sx={{fontSize:"22px", marginBottom:"5px"}}>{item.brand.name} - {item.collection.name}</Typography>
                        <Chip label={item.category.name} size="small" sx={{fontSize:"14px", marginBottom:"10px"}}/>
                        <Typography sx={{fontSize:"27px", lineHeight:"27px", fontWeight:"700", maxWidth:{xs:"100%",md:"80%"}, display:"block"}}>{item.name}</Typography>
                        {(item.description !== null)?<Typography sx={{whiteSpace:"break-spaces", marginTop:"20px"}}>{item.description}</Typography>:""}
                        <Typography sx={{whiteSpace:"break-spaces", marginTop:"20px"}}>IDR {item.price}</Typography>
                        {item.variation.length !== 0?
                        <Box sx={{display:"block", marginTop:"10px"}}>
                            <Typography sx={{display:"block", marginTop:"10px"}}>Size : </Typography>
                            <Box sx={{display:"flex", flexDirection:"row", flexWrap:"nowrap", alignContent:"center", justifyContent:"flex-start", alignItems:"center", gap:"5px"}}>
                                {item.variation.map((item)=>{
                                    return(
                                        <Box key={item.sku}>{item.is_instock?<Box>
                                            <Typography sx={{width:"50px", backgroundColor:"#aaf145", textAlign:"center", borderRadius:"5px", cursor:"pointer"}}>{item.value}</Typography>
                                        </Box>:""}</Box>
                                    )
                                })}
                            </Box>
                        </Box>:""}
                        <Button sx={{marginTop:"20px"}} variant="contained" color="green">Add to Cart</Button>
                    </Box>
                </Box>;
        }

        return (
        <Box component="div" className="show-detail-page">
            {(this.state.loaded !== false)?
            (this.state.product !== null)?
            <Box sx={{padding:"0px", minHeight:"450px"}} className="show-container">
                {productDetailContainer}
            </Box>:"Product Not Found":
            <LoadingSection />}
        </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProductDetails: data => dispatch(getProductDetails(data)),    
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductDetail));