import React, { Component } from "react";
import { connect } from "react-redux";
import ReactGA from "react-ga";

import { withRouter } from "react-router-dom";

import { getPress, loadMorePress } from "../redux/actions/presses";

import {
  Box,
  // Typography
} from "@mui/material";

// import PressSection from '../components/sections/presses';
import ComingSoonPress from "../assets/images/norrm-press-banner.jpg";

class Press extends Component {
  constructor(props) {
    super(props);
    this.state = {
      press: [],
      err: null,
    };

    this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    let page = this.props.press.length / process.env.REACT_APP_PERPAGE + 1;
    this.props.loadMorePress({ slug: "all", page: page, perpage: process.env.REACT_APP_PERPAGE }).then((res) => {});
  }

  expandGrid(e) {
    let type = e.target.attributes.getNamedItem("data-type").value;
    let valll = e.target.attributes.getNamedItem("data-quick").value;
    let docex = document.querySelector("." + type + ".expand-" + valll);

    const expanded = document.getElementsByClassName("grid-expanded");
    if (expanded.length !== 0) {
      expanded[0].classList.toggle("is-hidden");
      expanded[0].classList.toggle("grid-expanded");
    }
    docex.classList.toggle("is-hidden");
    docex.classList.toggle("grid-expanded");
  }

  closeExpand() {
    const expanded = document.getElementsByClassName("grid-expanded");
    if (expanded.length !== 0) {
      expanded[0].classList.toggle("is-hidden");
      expanded[0].classList.toggle("grid-expanded");
    }
  }

  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <Box component="div" className="show-page" fullwidth="true">
        <Box>
          <Box className="archive-container">
            <img src={ComingSoonPress} width="100%" alt="norrm press" />
            {/* <Box className='section-container-header'>
                        <Typography>Press</Typography>
                    </Box>
                    <PressSection /> */}
          </Box>
        </Box>
      </Box>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getPress: (data) => dispatch(getPress(data)),
    loadMorePress: (data) => dispatch(loadMorePress(data)),
    // login: userData => dispatch(login(userData)),
    // getArtist: data => dispatch(getArtist(data)),
    // getShow: data => dispatch(getShow(data))
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Press));
