import axios from 'axios';
import {BASE_URL, BASE_VERSION} from "../config";

export const loadMoreShow = (payload) => {
  return (dispatch) => {
    var perpage = payload.perpage;

    if(payload.slug === undefined || payload.slug === null){
      payload.slug = "all";
    }
    let paramPage = '?';
    if(payload.page !== undefined){
      paramPage += 'page='+payload.page;
    }
    if(perpage !== undefined){
      if(payload.page !== undefined){
        paramPage += '&perpage='+perpage;
      }else{
        paramPage += 'perpage='+perpage;
      }
    }else{
      paramPage += 'perpage='+process.env.REACT_APP_PERPAGE;
    }

    return( axios.get(BASE_URL+BASE_VERSION+'show/'+payload.slug+paramPage,
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, 
        validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        dispatch({ type: 'APPEND_SHOW', payload });
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const getShowDetails = (payload) => {
  return (dispatch) => {
    if(payload.slug === undefined || payload.slug === null){
      payload.slug = "all";
    }

    let paramPage = '?';
    if(payload.page !== undefined){
      paramPage += 'page='+payload.page;
    }
    if(payload.perpage !== undefined){
      if(payload.page !== undefined){
        paramPage += '&perpage='+payload.perpage;
      }else{
        paramPage += 'perpage='+payload.perpage;
      }
    }

    return( axios.get(BASE_URL+BASE_VERSION+'show/'+payload.slug+paramPage, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        // dispatch({ type: 'SET_PROGRAM', payload })
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const getShow = (payload) => {
  return (dispatch) => {
    if(payload.slug === undefined || payload.slug === null){
      payload.slug = "all";
    }
    let paramPage = '?';
    if(payload.page !== undefined){
      paramPage += 'page='+payload.page;
    }
    if(payload.perpage !== undefined){
      if(payload.page !== undefined){
        paramPage += '&perpage='+payload.perpage;
      }else{
        paramPage += 'perpage='+payload.perpage;
      }
    }

    return( axios.get(BASE_URL+BASE_VERSION+'show/'+payload.slug+paramPage,
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, 
        validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        dispatch({ type: 'SET_SHOW', payload })
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const addShow = (payload) => {
  return (dispatch) => {
    return( axios.post(BASE_URL+BASE_VERSION+'show', {
      title:payload.show.title,
      episode:payload.show.episode,
      slug:payload.show.slug,
      mixcloudurl:payload.show.mixcloudurl,
      soundcloudurl:payload.show.soundcloudurl,
      description:payload.show.description,
      scheduled_at:payload.show.scheduled_at,
      idimage:payload.show.idimage,
      idaudio:payload.show.idaudio,
      idprogram:payload.show.idprogram,
      artists:payload.show.artists,
      genres:payload.show.genres,
      tags:payload.show.tags
    }, {
      headers: { 
        Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
      }, validateStatus: () => true
    })
    .then((response) => {
      payload = response.data;
      return payload;
    })
    .catch((error) => {
      return error;
    })
    )
  }
}

export const updateShow = (payload) => {
  return (dispatch) => {
    return( axios.put(BASE_URL+BASE_VERSION+'show/'+payload.id, 
      {
        name:payload.show.name,
        slug:payload.show.slug,
        mixcloudurl:payload.show.mixcloudurl,
        soundcloudurl:payload.show.soundcloudurl,
        description:payload.show.description,
        schedule:payload.show.schedule,
        idimage:payload.show.idimage,
        idaudio:payload.show.idaudio,
        idprogram:payload.show.idprogram,
        artists:payload.show.artists,
        genres:payload.show.genres,
        tags:payload.show.tags,
      }, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        },
        validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const deleteShow = (payload) => {
  return (dispatch) => {
    return( axios.delete(BASE_URL+BASE_VERSION+'show', 
      {
        slug:payload.slug
      }, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        },
        validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}