import React, { Component } from 'react';
import { connect } from "react-redux";
import ReactGA from 'react-ga';

import {
    NavLink,
    withRouter
} from "react-router-dom";

import { getShowDetails } from "../redux/actions/shows";
import { playMixcloud } from "../redux/actions/utils";

import { 
    Box,
    Button,
    Typography 
} from '@mui/material';

import Tags from '../components/stacks/tags';
import Hosts from '../components/stacks/hosts';
import Genres from '../components/stacks/genres';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import LoadingSection from '../components/sections/loadings';

class ShowDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded:false,
            show:null,
            err:null
        };
        
        this.playArchives = this.playArchives.bind(this);
    }

    playArchives(e){
        this.props.playMixcloud({key:e.currentTarget.attributes["data-mixcloudkey"].value});
    }

    componentDidMount(){
        ReactGA.pageview(this.props.location.pathname);
        this.props.getShowDetails({slug:this.props.match.params.slug}).then((res)=>{
            if(res.code === 200){            
                this.setState({show:res.data, loaded:true});
            }
        })
    }

    render() {
        var item = this.state.show;
        var showDetailContainer = "";
        let mixcloudkey = "";
        let playarchiveBtn = "";

        if(this.state.show !== null){
            if(item.mixcloudurl !== null){
                mixcloudkey = item.mixcloudurl.replace("https://www.mixcloud.com", "");
                playarchiveBtn = <Button sx={{marginTop:{xs:"20px", md:"30px"}, marginBottom:"10px"}} variant="contained" color="green" startIcon={<PlayCircleOutlineIcon />} onClick={this.playArchives} data-mixcloudkey={mixcloudkey}>Play Archives</Button>
            }

            showDetailContainer = 
                <Box className={"grid-container-item-section show"} sx={{display:"grid", height:"auto", gridTemplateColumns:"repeat(auto-fit, 50%)"}}>
                    <Box className="row50 cover-image-full" sx={{backgroundImage:"url("+encodeURI(item.image.fullurl)+")"}}></Box>
                    <Box className="row50" sx={{padding:{xs:"10px", md:"20px"}}}>
                        <Typography sx={{fontSize:"22px", marginBottom:"5px"}}>#{item.episode}</Typography>
                        <Typography sx={{fontSize:"27px", lineHeight:"27px", fontWeight:"700", maxWidth:{xs:"100%",md:"80%"}, display:"block"}} component={NavLink} to={"/show/"+item.slug}>{item.title}</Typography>
                        <Hosts items={item.artists}/>
                        {playarchiveBtn}
                        {(item.description !== null)?<Typography sx={{whiteSpace:"break-spaces", marginTop:"20px"}}>{item.description}</Typography>:""}                                
                        <Genres items={item.genres}/>
                        <Tags items={item.tags}/>
                    </Box>
                </Box>;
        }

        return (
        <Box component="div" className="show-detail-page">
            {(this.state.loaded !== false)?
            (this.state.show !== null)?
            <Box sx={{padding:"0px", minHeight:"450px"}} className="show-container">
                {showDetailContainer}
            </Box>:"Show Not Found":
            <LoadingSection />}
        </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        playMixcloud: data => dispatch(playMixcloud(data)),
        getShowDetails: data => dispatch(getShowDetails(data)),    
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShowDetail));