import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter
} from "react-router-dom";

import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

import FormControlLabel from '@mui/material/FormControlLabel';
// import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';

import { AddCircle, Delete } from '@mui/icons-material';

class ModalTag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:{}
        };
        this.deleteItem = this.deleteItem.bind(this);
    }

    deleteItem(){
        if (window.confirm('Are you sure you want to save this thing into the database?')) {
            console.log('Thing was saved to the database.');
        } else {
            console.log('Thing was not saved to the database.');
        }
    }

    render() {
        let titlePopup = "Create Tag";
        let deleteButton = "";

        switch(this.props.modalMode){
            case "edit":
                titlePopup = "Edit Tag"
                deleteButton = <Button sx={{margin:'5px'}} variant="contained" endIcon={<Delete />} color="error" onClick={this.props.handleDelete}> Delete </Button>
                break;
            default:
                titlePopup = "Create Tag";
                break;
        }

        return (
            <Modal open={this.props.isModalOpen} onClose={this.props.closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ display:'flex', alignItems:'center', justifyContent:'center'}}
            >
                <Box sx={{backgroundColor:"#fff", padding:"10px", borderRadius: "8px", minHeight:'280px', minWidth:'480px', maxWidth:'640px'}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb:'10px', textAlign:'center'}}>{titlePopup}</Typography>
                    <Box component="form"
                        sx={{ '& .MuiTextField-root': { margin:'5px' } }}//width: 'calc(50% - 10px)' 
                        // noValidate
                        autoComplete="off"
                        onSubmit={(this.props.modalMode === "edit")?this.props.handleUpdate:this.props.handleSubmit}
                    >
                    <div>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} autoFocus={true} value={this.props.name || ""} required={true} id="input-name" label="Name" type="text" name="name" onChange={this.props.handleChange}/>
                    </div>
                    <div>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} 
                            id="input-caption"
                            label="Caption"
                            name="caption"
                            multiline
                            rows={3}
                            onChange={this.props.handleChange}
                            value={this.props.caption || ""}
                        />
                    </div>
                    <div>
                        <FormControlLabel
                            control={<Switch 
                            id="input-is_active"
                            name="is_active"
                            value={this.props.is_active}
                            checked={this.props.is_active} 
                            onChange={this.props.handleChange} />}
                            label="Is Active?"
                        />
                    </div>
                    <Box sx={{ display:'flex', alignItems:'right', justifyContent:'right'}}>
                        {deleteButton}
                        {(this.props.isLoading === true)?<Button sx={{margin:'5px'}} disabled={true} variant="contained" endIcon={<AddCircle />} type="submit"> Please Wait </Button>:<Button sx={{margin:'5px'}} variant="contained" endIcon={<AddCircle />} type="submit"> Submit </Button>}
                    </Box>
                    </Box>
                </Box>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // getLibrary: libraryData => dispatch(getLibrary(libraryData)),
        // updateLibrary: libraryData => dispatch(updateLibrary(libraryData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalTag));