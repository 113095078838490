import React, { Component } from 'react';
import { connect } from "react-redux";
import ReactGA from 'react-ga';

import {
    withRouter
} from "react-router-dom";

import { login } from "../redux/actions/users";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:null
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event){
        event.preventDefault();
        let ctx = this;
        ctx.setState({ err:null });

        const data = new FormData(event.currentTarget);
        this.props.login({
            email: data.get('email'),
            password: data.get('password'),
        }).then(function (data){
            if(data.code !== 200){
                ctx.setState({err:data.message});
            }
        }).catch(function (e) {
            ctx.setState({err:"Something error"});
        });
    }

    componentDidMount(){
        ReactGA.pageview(this.props.location.pathname);
    }

    render() {
        let err = "";
        if(this.state.err !== null){
            err = <Grid container className="alert-text">
                    <Grid item xs>
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {this.state.err} - <strong>Check again</strong>
                    </Alert>
                    </Grid>
                </Grid>;
        }

        return (
            <Container component="div" maxWidth="xs" className="login-page">
                <Box sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        />
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        />
                        {err}
                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        >
                        Sign In
                        </Button>
                    </Box>
                </Box>
            </Container>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        login: userData => dispatch(login(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));