import React, { Component } from 'react';
import { connect } from "react-redux";
import ReactGA from 'react-ga';

import {
    withRouter
} from "react-router-dom";

import { playMixcloud } from "../redux/actions/utils";
import { getShow, loadMoreShow} from "../redux/actions/shows";

import { 
    Box,     
    Typography 
} from '@mui/material';

import ShowSection from '../components/sections/shows';

class Show extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show:[],
            err:null
        };

        this.loadMore = this.loadMore.bind(this);
    }

    loadMore(e){
        let page = (this.props.show.length / process.env.REACT_APP_PERPAGE) + 1;
        this.props.loadMoreShow({slug:"all", page:page, perpage:process.env.REACT_APP_PERPAGE})
        // .then((res)=>{
            //
        // })
    }

    componentDidMount(){
        ReactGA.pageview(this.props.location.pathname);
    }

    render() {
        return (
        <Box component="div" className="show-page" fullwidth="true">            
            <Box>
                <Box className='archive-container'>
                    <Box className='section-container-header'>
                        <Typography>Shows</Typography>
                    </Box>
                    <ShowSection />
                </Box>
            </Box>
        </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getShow: data => dispatch(getShow(data)),
        loadMoreShow: data => dispatch(loadMoreShow(data)),        
        playMixcloud: data => dispatch(playMixcloud(data)),
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Show));