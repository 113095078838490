import axios from 'axios';
import {BASE_URL, BASE_VERSION} from "../config";

export const loadMoreTag = (payload) => {
  return (dispatch) => {
    var perpage = payload.perpage;

    if(payload.slug === undefined || payload.slug === null){
      payload.slug = "all";
    }

    let paramPage = '?';
    if(payload.page !== undefined){
      paramPage += 'page='+payload.page;
    }
    if(perpage !== undefined){
      if(payload.page !== undefined){
        paramPage += '&perpage='+perpage;
      }else{
        paramPage += 'perpage='+perpage;
      }
    }else{
      paramPage += 'perpage='+process.env.REACT_APP_PERPAGE;
    }

    return( axios.get(BASE_URL+BASE_VERSION+'tag/'+payload.slug+paramPage, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        dispatch({ type: 'APPEND_TAG', payload })
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const getTagDetails = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'tag/'+payload.slug, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;        
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const getTag = (payload) => {
  return (dispatch) => {
    if(payload.slug === undefined || payload.slug === null){
      payload.slug = "all";
    }

    let paramPage = '?';
    if(payload.page !== undefined){
      paramPage += 'page='+payload.page;
    }
    if(payload.perpage !== undefined){
      if(payload.page !== undefined){
        paramPage += '&perpage='+payload.perpage;
      }else{
        paramPage += 'perpage='+payload.perpage;
      }
    }

    return( axios.get(BASE_URL+BASE_VERSION+'tag/'+payload.slug+paramPage, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        dispatch({ type: 'SET_TAG', payload })
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const addTag = (payload) => {
  return (dispatch) => {
    return( axios.post(BASE_URL+BASE_VERSION+'tag', {
      name: payload.name,
      caption: payload.caption,
      is_active:payload.is_active
    }, {
      headers: { 
        Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
      }, validateStatus: () => true
    })
    .then((response) => {
      payload = response.data;
      return payload;
    })
    .catch((error) => {
      console.log(error);
      return error;
    })
    )
  }
}

export const updateTag = (payload) => {
  return (dispatch) => {
    return( axios.put(BASE_URL+BASE_VERSION+'tag/'+payload.id, {
      name: payload.name,
      caption: payload.caption,
      is_active:payload.is_active
    }, {
      headers: { 
        Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
      }, validateStatus: () => true
    })
    .then((response) => {
      payload = response.data;
      return payload;
    })
    .catch((error) => {
      console.log(error);
      return error;
    })
    )
  }
}

export const deleteTag = (payload) => {
  return (dispatch) => {
    return( axios.delete(BASE_URL+BASE_VERSION+'tag/'+payload.id,
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        },
        validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}