import React, { Component } from 'react';
import { connect } from "react-redux";
import ReactGA from 'react-ga';

import {
    Link,
    withRouter
} from "react-router-dom";

import { getShow } from "../redux/actions/shows";
import { getArtist } from "../redux/actions/artists";
import { getProgram } from "../redux/actions/programs";
import { playMixcloud } from "../redux/actions/utils";

import { 
    Box,    
    Typography
} from '@mui/material';

import Slider from "react-slick";

import ShowSection from '../components/sections/shows';
// import PressSection from '../components/sections/presses';
import ArtistSection from '../components/sections/artists';
import ProgramSection from '../components/sections/programs';
import ScheduleSection from '../components/sections/schedules';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show:[],
            artist:[],
            program:[],
            slideshow:[],
            mobileSlideshow:[],
            press:[],
            err:null
        };
    }

    componentDidMount(){
        ReactGA.pageview(this.props.location.pathname);
    }

    render() {        
        const settings = {
            dots: true,
            infinite: true,
            autoplay:true,
            autoplaySpeed:5000,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows:true
        };

        let slideshowContainer = "";
        let slideshows = this.props.slideshow;
        // let isMobile = false;
        if(window.innerWidth < 640){
            // isMobile = true;
            slideshows= this.props.mobileSlideshow;
        }

        if(slideshows.length !== 0){
            slideshowContainer = slideshows.map((item, idx)=>{
                return(
                    <Box key={idx} 
                        sx={{
                            display:"block !important", 
                            position:"relative",
                            // backgroundImage:"url("+encodeURI(item.image.fullurl)+")",
                            // minHeight:"500px",
                            backgroundSize:"cover",
                            backgroundPosition:"center",
                            backgroundRepeat:"no-repeat"
                        }}>
                        <Box sx={{width:"100%"}} className="slider-image-item">
                            <img src={encodeURI(item.image.fullurl)} alt={item.title}/>
                        </Box>
                        <Box className="slideshow-detail">
                            {(item.title !== "#")?<Box className="slideshow-detail-title">{item.title}</Box>:""}
                            {(item.caption !== null)?<Box className="slideshow-detail-description">{item.caption}</Box>:""}
                            {(item.link !== null)?<Box className="slideshow-detail-link"><Link to={{ pathname: item.link }} target="_blank" rel="noopener noreferrer">{item.link_label}</Link></Box>:""}
                        </Box>
                    </Box>
                )
            })
        }

        return (
        <Box component="div" className="home-page" fullwidth="true">
            <Box sx={{ borderBottom:"1px solid #000"}}>
                <Slider {...settings} className="main-slider">
                    {slideshowContainer}
                </Slider>
            </Box>

            <ScheduleSection/>

            <Box>
                <Box className='archive-container' id="show-container">
                    <Box className='section-container-header'>
                        <Typography sx={{fontWeight:"700", fontSize:"20px", padding:"8px"}}>Shows</Typography>
                    </Box>
                    <ShowSection minusOne={true}/>
                </Box>
            </Box>

            <Box>
                <Box className='archive-container'>
                    <Box className='section-container-header'>
                        <Typography sx={{fontWeight:"700", fontSize:"20px", padding:"8px"}}>Artists</Typography>
                    </Box>
                    <ArtistSection minusOne={true}/>
                </Box>
            </Box>

            <Box>
                <Box className='archive-container'>
                    <Box className='section-container-header'>
                        <Typography sx={{fontWeight:"700", fontSize:"20px", padding:"8px"}}>Programs</Typography>
                    </Box>
                    <ProgramSection minusOne={true}/>
                </Box>
            </Box>
            
            {/* <Box>
                <Box className='archive-container'>
                    <Box className='section-container-header'>
                        <Typography sx={{fontWeight:"700", fontSize:"20px", padding:"8px"}}>Press</Typography>
                    </Box>
                    <PressSection minusOne={true}/>
                </Box>
            </Box> */}
        </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        playMixcloud: data => dispatch(playMixcloud(data)),
        getProgram: data => dispatch(getProgram(data)),
        getArtist: data => dispatch(getArtist(data)),
        getShow: data => dispatch(getShow(data)),
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));