import axios from 'axios';
import {BASE_URL, BASE_VERSION} from "../config";

export const getLibrary = (payload) => {
  return (dispatch) => {
    if(payload.slug === undefined || payload.slug === null){
      payload.slug = "all";
    }

    let paramPage = '?';
    if(payload.page !== undefined){
      paramPage += 'page='+payload.page;
    }

    if(payload.perpage !== undefined){
      if(payload.page !== undefined){
        paramPage += '&perpage='+payload.perpage;
      }else{
        paramPage += 'perpage='+payload.perpage;
      }
    }

    if(payload.type !== undefined || payload.type !== null){
      paramPage += "&type="+payload.type;
    }
    
    return( axios.get(BASE_URL+BASE_VERSION+'library/'+payload.slug+paramPage, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const addLibrary = (payload) => {
  return (dispatch) => {
    const config = {
      validateStatus: () => true,
      headers: { Authorization: "Bearer "+window.localStorage.getItem('app_tt') },
      onUploadProgress: payload.onProgress,
      //'Content-Type': 'multipart/form-data',
      // (progressEvent) => {
      //   const { loaded, total } = progressEvent;
      //   payload.is_upload = true;          
      //   payload.loaded = Math.floor((loaded / total) * 100);
      //   payload.total = total;
      //   dispatch({ type: 'UPLOAD_PROGRESS', payload })
      // },
    }
    
    const formData = new FormData();
    
    // if(payload.file !== null){
    //   payload.file.forEach(file => {
    //     formData.append('file[]', file);
    //   });
    // }

    if(payload.file !== null){
      formData.append('file', payload.file);
    }  
    if(payload.name !== null){
      formData.append('name', payload.name);
    }
    if(payload.caption !== null){
      formData.append('caption', payload.caption);
    }

    return( axios.post(BASE_URL+BASE_VERSION+'library', formData, config)
    .then((response) => {
      payload = response.data;
      return payload;
    })
    .catch((error) => {
      console.log(error);
      return error;
    })
    )
  }
}

export const updateLibrary = (payload) => {
  return (dispatch) => {
    return( axios.put(BASE_URL+BASE_VERSION+'library/'+payload.id, 
      {
        name:payload.name,
        caption:payload.caption
      }, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        },
        validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const deleteLibrary = (payload) => {
  return (dispatch) => {
    return( axios.delete(BASE_URL+BASE_VERSION+'library/'+payload.id, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        },
        validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}