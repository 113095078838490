import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import ReactGA from 'react-ga';

import {
    NavLink,
    withRouter
} from "react-router-dom";

import { 
    getGenreDetails
} from "../redux/actions/genres";

import { 
    Box, 
    Grid,
    Typography 
} from '@mui/material';

import LoadingSection from '../components/sections/loadings';

class GenreDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shows:[],
            loaded:false,
            genre:null,
            err:null
        };
    }

    componentDidMount(){
        ReactGA.pageview(this.props.location.pathname);

        this.props.getGenreDetails({slug:this.props.match.params.slug}).then((res)=>{
            console.log(res);
            if(res.code === 200){            
                this.setState({genre:res.data, loaded:true, shows:res.data.shows});
            }
        })
    }

    render() {
        let ShowContainer = "";
        let shows = this.state.shows;
        if(shows !== undefined && shows.length !== 0){
            ShowContainer = shows.map((item, idx)=>{
                return(
                <Fragment key={idx}>
                    <Grid component={NavLink} to={"/show/"+item.slug} data-type="artist" data-quick={idx} item className='grid-container-item' sx={{backgroundImage:"url("+encodeURI(item.image.fullurl)+")"}}></Grid>                    
                </Fragment>
                )
            })
        }

        return (
            <Box component="div" className="genre-detail-page">
                {(this.state.loaded !== false)?
                    (this.state.genre !== null)?
                    <Box sx={{ minHeight:"450px" }} className="genre-container">
                        {this.state.genre.image!==null?<Box sx={{
                            display:"block",
                            minHeight:"450px",
                            borderBottom:"1px solid #000000",
                            backgroundSize:"cover",
                            backgroundPosition:"center",
                            backgroundImage:"url("+encodeURI(this.state.genre.image?.fullurl)+")",
                        }}></Box>:""}

                        <Box sx={{
                            padding:"30px",                         
                            textAlign:"center",
                            borderBottom:"1px solid #000000",
                        }}>
                            <Typography sx={{fontSize:"24px", fontWeight:"800"}}>{this.state.genre.name}</Typography>
                            {this.state.genre.caption!==null?<Typography sx={{marginTop:"20px"}}>{this.state.genre.caption}</Typography>:""}                        
                        </Box>

                        <Grid container spacing={0} className="grid-container">
                            {ShowContainer}
                        </Grid>
                    </Box>
                    :"Genre Not Found"
                :<LoadingSection />}
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getGenreDetails: data => dispatch(getGenreDetails(data)),
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenreDetail));