import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
    //Link,
} from "react-router-dom";

import { getLibrary } from "../../redux/actions/libraries";

//import { Table, TableContainer, TableHead, TableRow, TableCell, tableCellClasses, Paper, TableBody, Button} from '@mui/material';
//import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
//import Typography from '@mui/material/Typography';

//import Box from '@mui/material/Box';
//import Icon from '@mui/material/Icon';

//<Paper elevation={0} />

class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:{}
        };
        
        //this.onLogout = this.onLogout.bind(this);
    }

    // onLogout(params) {
        
    // }

    componentDidMount(){
        // this.props.getLibrary({}).then((res)=>{
        //     console.log(res);
        // })
    }

    render() {
        const theme = createTheme();
        
        return (
            <ThemeProvider theme={theme}>
            <Box sx={{ display: 'block' }}>
                <Container component="div" className='panel-section-container' maxWidth="xl">
                    <span>Slider</span>
                </Container>
            </Box>
            </ThemeProvider>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getLibrary: libraryData => dispatch(getLibrary(libraryData))
        //logout: userData => dispatch(logout(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Slider));