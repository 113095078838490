const initialState = {
    isLogin:false,
    userData:null,
    audioPlayer:null,
    isAudioPlay:"none",
    schedule:[],
    product:[],
    productFullLoaded:false,
    show:[],
    showFullLoaded:false,
    program:[],
    programFullLoaded:false,
    artist:[],
    artistFullLoaded:false,
    press:[],
    pressFullLoaded:false,
    genre:[],
    genreFullLoaded:false,
    tag:[],
    tagFullLoaded:false,
    slideshow:[],
    mobileSlideshow:[]
};

function rootReducer(state = initialState, action) {
    if (action.type === 'SET_LOGIN') {
        window.localStorage.setItem('app_tt', action.payload.data.access_token)
        return Object.assign({}, state, {
            isLogin: true,
            userData: action.payload.data
        });
    }else if (action.type === 'SET_SCHEDULE') {
        return Object.assign({}, state, {
            schedule: action.payload.data
        });
    }else if (action.type === 'SET_PRODUCT') {
        return Object.assign({}, state, {
            product: action.payload.data,
            productFullLoaded:action.payload.data.length !== parseInt(process.env.REACT_APP_PERPAGE)?true:false
        });
    }else if (action.type === 'APPEND_PRODUCT') {
        return Object.assign({}, state, {
            product: state.product.concat(action.payload.data),
            productFullLoaded:action.payload.data.length !== parseInt(process.env.REACT_APP_PERPAGE)?true:false
        });
    }else if (action.type === 'SET_SHOW') {
        return Object.assign({}, state, {
            show: action.payload.data,
            showFullLoaded:action.payload.data.length !== parseInt(process.env.REACT_APP_PERPAGE)?true:false
        });
    }else if (action.type === 'APPEND_SHOW') {
        return Object.assign({}, state, {
            show: state.show.concat(action.payload.data),
            showFullLoaded:action.payload.data.length !== parseInt(process.env.REACT_APP_PERPAGE)?true:false
        });
    }else if (action.type === 'SET_PROGRAM') {
        return Object.assign({}, state, {
            program: action.payload.data,
            programFullLoaded:action.payload.data.length !== parseInt(process.env.REACT_APP_PERPAGE)?true:false
        });
    }else if (action.type === 'APPEND_PROGRAM') {
        return Object.assign({}, state, {
            program: state.program.concat(action.payload.data),
            programFullLoaded:action.payload.data.length !== parseInt(process.env.REACT_APP_PERPAGE)?true:false
        });
    }else if (action.type === 'SET_ARTIST') {
        return Object.assign({}, state, {
            artist: action.payload.data,
            artistFullLoaded:action.payload.data.length !== parseInt(process.env.REACT_APP_PERPAGE)?true:false
        });
    }else if (action.type === 'APPEND_ARTIST') {
        return Object.assign({}, state, {
            artist: state.artist.concat(action.payload.data),
            artistFullLoaded:action.payload.data.length !== parseInt(process.env.REACT_APP_PERPAGE)?true:false
        });
    }else if (action.type === 'SET_PRESS') {
        return Object.assign({}, state, {
            press: action.payload.data,
            pressFullLoaded:action.payload.data.length !== parseInt(process.env.REACT_APP_PERPAGE)?true:false
        });
    }else if (action.type === 'APPEND_PRESS') {
        return Object.assign({}, state, {
            press: state.press.concat(action.payload.data),
            pressFullLoaded:action.payload.data.length !== parseInt(process.env.REACT_APP_PERPAGE)?true:false
        });
    }else if (action.type === 'SET_GENRE') {
        return Object.assign({}, state, {
            genre: action.payload.data,
            genreFullLoaded:action.payload.data.length !== parseInt(process.env.REACT_APP_PERPAGE)?true:false
        });        
    }else if (action.type === 'APPEND_GENRE') {
        return Object.assign({}, state, {
            genre: state.genre.concat(action.payload.data),
            genreFullLoaded:action.payload.data.length !== parseInt(process.env.REACT_APP_PERPAGE)?true:false
        });
    }else if (action.type === 'SET_TAG') {
        return Object.assign({}, state, {
            tag: action.payload.data,
            tagFullLoaded:action.payload.data.length !== parseInt(process.env.REACT_APP_PERPAGE)?true:false
        });
    }else if (action.type === 'APPEND_TAG') {
        return Object.assign({}, state, {
            tag: state.tag.concat(action.payload.data),
            tagFullLoaded:action.payload.data.length !== parseInt(process.env.REACT_APP_PERPAGE)?true:false
        });
    }else if (action.type === 'SET_SLIDESHOW') {
        return Object.assign({}, state, {
            slideshow: action.payload.data
        });
    }else if (action.type === 'SET_MOBILE_SLIDESHOW') {
        return Object.assign({}, state, {
            mobileSlideshow: action.payload.data
        });    
    }else if (action.type === 'STAY_INSIDE') {
        return Object.assign({}, state, {
            isLogin: true,
            userData: null
        });
    }else if (action.type === 'SET_LOGOUT') {
        window.localStorage.removeItem('app_tt') 
        return Object.assign({}, state, {
            isLogin:false,
            userData:null
        });
    }else if (action.type === 'PLAY_MIXCLOUD') {
        if(state.audioPlayer){
            state.audioPlayer.pause();
        }
        return Object.assign({}, state, {
            isAudioPlay: "mixcloud",
            audioPlayer: null
        });
    }else if (action.type === 'PLAY_RADIO') {
        action.payload.audioPlayer.play();
        return Object.assign({}, state, {
            isAudioPlay: "radio",
            audioPlayer: action.payload.audioPlayer
        });
    }
    else if (action.type === 'RESET_AUDIO') {
        if(state.audioPlayer !== null){
            state.audioPlayer.pause();
        }
        return Object.assign({}, state, {
            audioPlayer: null     
        });
    }
    else if (action.type === 'STOP_AUDIO') {
        if(state.audioPlayer !== null){
            state.audioPlayer.pause();
        }
        return Object.assign({}, state, {
            isAudioPlay: "none",   
            audioPlayer: null     
        });
    }else{
        return state;
    }
    
    // else if (action.type === 'START_LIVE') {
    //     action.payload.audioPlayer.play();
    //     return Object.assign({}, state, {
    //         isAudioPlay: "radio",
    //         audioPlayer: action.payload.audioPlayer
    //     });
    // }else if (action.type === 'STOP_LIVE') {
    //     action.payload.audioPlayer.play();
    //     return Object.assign({}, state, {
    //         isAudioPlay: "radio",
    //         audioPlayer: action.payload.audioPlayer
    //     });
    // }

//   else if (action.type === 'MYBAND') {
//     //console.log(action.payload.data.bands);
//     return Object.assign({}, state, {
//       //userBand
//       bandData:action.payload.data.bands//state.userBand.concat(action.payload.data.bands)
//     });
//   }else if (action.type === 'LOADED_BAND') {
//     return Object.assign({}, state, {
//       bandData: action.payload.data.bands//state.userBand.concat(action.payload.data.bands)
//     });
//   }else if (action.type === 'LOADED_BAND_ALBUM') {
//     return Object.assign({}, state, {
//       bandAlbum: action.payload.data.data//state.bandAlbum.concat(action.payload.data.data)//state.userBand.concat(action.payload.data.bands)
//     });
//   }else if (action.type === 'LOADED_BAND_CONTENT') {
//     return Object.assign({}, state, {
//       bandContent: action.payload.data.data//state.bandAlbum.concat(action.payload.data.data)//state.userBand.concat(action.payload.data.bands)
//     });
//   }else if (action.type === 'CREATE_BAND') {
//     return Object.assign({}, state, {
//       //userBand
//       bandData: action.payload.data.data//state.userBand.concat(action.payload.data.data)
//     });
//   }else if (action.type === 'LOGIN') {
//     return Object.assign({}, state, {
//       isLogin: 'false'
//     });
//   }else if (action.type === 'UPLOAD_PROGRESS') {
//     return Object.assign({}, state, {
//       uploadData: {isUpload:true,loaded:action.payload.loaded,total:action.payload.total}
//     });
//   }else if (action.type === 'UPLOAD_COMPLETE') {
//     return Object.assign({}, state, {
//       uploadData: {isUpload:false,loaded:0,total:0}
//     });
//   }else if(action.type === 'SET_PUSHER'){
//     return Object.assign({}, state, {
//       pusherChannel: action.payload
//     });
//   }
}

export default rootReducer;