import React, { Component } from "react";
import { connect } from "react-redux";
import ReactGA from "react-ga";

import { withRouter } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import GoogleMapReact from "google-map-react";

// import ComingSoonShop from '../assets/images/norrm-shop-banner.jpg'
// import ShopSection from "../components/sections/shops";

const AnyReactComponent = ({ text }) => (
  <div
    className="blink"
    style={{
      color: "#fff",
      width: "50px",
      height: "50px",
      display: "flex",
      fontSize: "9px",
      borderRadius: "50%",
      textAlign: "center",
      alignItems: "center",
      alignContent: "center",
      backgroundColor: "#f00",
      justifyContent: "center",
      transform: "translateY(-25px) translateX(-25px)",
    }}
  >
    {text}
  </div>
);

class Bar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      err: null,
    };
  }

  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <Box component="div" className="show-page" fullwidth="true">
        <Box>
          <Box className="archive-container">
            <Box className="section-container-header">
              <Typography>Radio Bar</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              minHeight: 500,
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
            className="radiobar-info-container"
          >
            <Box
              className="content-50"
              sx={{
                width: "50%",
                height: "500px",
                position: "relative",
              }}
            >
              <GoogleMapReact
                style={{ width: "100%", height: "100%" }}
                bootstrapURLKeys={{ key: "AIzaSyCoUIl4wu_ftf3ETRQO56GKElSl5dRjiV0" }}
                defaultCenter={{
                  lat: -6.916981,
                  lng: 107.625975,
                }}
                defaultZoom={14}
                // defaultZoom={17}
              >
                {/* <AnyReactComponent lat={-6.916981} lng={107.625975} text="Norrm Radio bar" /> */}
              </GoogleMapReact>
            </Box>
            <Box
              className="content-50"
              sx={{
                width: "50%",
                height: "500px",
                padding: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Coming Soon
            </Box>
          </Box>
        </Box>
        {/* -6.916981, 107.625975 */}
        {/* <AnyReactComponent lat={59.955413} lng={30.337844} text="My Marker" /> */}
        {/* map google key */}
        {/* AIzaSyCoUIl4wu_ftf3ETRQO56GKElSl5dRjiV0 */}
      </Box>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // getPress: data => dispatch(getPress(data)),
    // loadMorePress: data => dispatch(loadMorePress(data)),
    // login: userData => dispatch(login(userData)),
    // getArtist: data => dispatch(getArtist(data)),
    // getShow: data => dispatch(getShow(data))
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bar));
