import React, { Component } from 'react';
import { connect } from "react-redux";
import ReactGA from 'react-ga';

import {
    withRouter,
    Switch,
    Route
} from "react-router-dom";

import { login } from "../redux/actions/users";

import Sidemenu from "../components/sidemenu";

import Home from "../pages/dashboards/home";
import Artist from "../pages/dashboards/artist";
import Library from "../pages/dashboards/library";
import Genre from "../pages/dashboards/genre";
import Press from "../pages/dashboards/press";
import PressCategory from "../pages/dashboards/pressCategory";
import PressTag from "../pages/dashboards/pressTag";
import Program from "../pages/dashboards/program";
import Setting from "../pages/dashboards/setting";
import Slider from "../pages/dashboards/slider";
import Show from "../pages/dashboards/show";
import Tag from "../pages/dashboards/tag";

import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event){
        event.preventDefault();
        let ctx = this;
        ctx.setState({ err:null });
        const data = new FormData(event.currentTarget);
        this.props.login({
            email: data.get('email'),
            password: data.get('password'),
        }).then(function (data){
            if(data.code !== 200){
                ctx.setState({err:data.message});
            }
        }).catch(function (e) {
            ctx.setState({err:"Something error"});
        });
    }
    
    componentDidMount(){
        ReactGA.pageview(this.props.location.pathname);
    }

    render() {
        const url = this.props.match.url;
        const theme = createTheme();

        return (
            <ThemeProvider theme={theme}>
                <Container component="main" className='main-section-container' maxWidth={false}>
                    <CssBaseline />
                    <div className='section-left-panel'><Sidemenu /></div>
                    <div className='section-right-panel'>
                        <Switch>                        
                            <Route exact path={`${url}/`}>
                                <Home />
                            </Route>
                            <Route path={`${url}/show`}>
                                <Show />
                            </Route>
                            <Route path={`${url}/program`}>
                                <Program />
                            </Route>
                            <Route path={`${url}/residence`}>
                                <Artist />
                            </Route>
                            <Route path={`${url}/about`}>
                                <Artist />
                            </Route>
                            <Route path={`${url}/contact`}>
                                <Tag />
                            </Route>
                            <Route path={`${url}/press`}>
                                <Genre />
                            </Route>
                            <Route path={`${url}/shop`}>
                                <Library />
                            </Route>
                            <Route path={`${url}/press`}>
                                <Press />
                            </Route>
                            <Route path={`${url}/profile`}>
                                <PressCategory />
                            </Route>
                            <Route path={`${url}/login`}>
                                <PressTag />
                            </Route>
                            <Route path={`${url}/register`}>
                                <Slider />
                            </Route>
                            <Route path={`${url}/cart`}>
                                <Setting />
                            </Route>                            
                        </Switch>
                    </div>
                </Container>
            </ThemeProvider>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createTheme,
        login: userData => dispatch(login(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));