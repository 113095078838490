import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
    //Link,
} from "react-router-dom";

import { getLibrary } from "../../redux/actions/libraries";
import { getCountries, getCountry } from "../../redux/actions/utils";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';

import { AddCircle, Delete } from '@mui/icons-material';

//Table, TableContainer, TableHead, TableRow, TableCell, tableCellClasses, Paper, TableBody, 
//import { styled } from '@mui/material/styles';
//import { createTheme } from '@mui/material/styles';//ThemeProvider
//import Grid from '@mui/material/Grid';
//import Container from '@mui/material/Container';
//import Typography from '@mui/material/Typography';

//import Box from '@mui/material/Box';
//import Icon from '@mui/material/Icon';

class ModalArtist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countries:[],
            images:[],
            err:{}
        };
        this.deleteItem = this.deleteItem.bind(this);
    }

    deleteItem(){
        if (window.confirm('Are you sure you want to save this thing into the database?')) {
            console.log('Thing was saved to the database.');
        } else {
            console.log('Thing was not saved to the database.');
        }
    }

    componentDidMount(){
        // console.log(this.props.item);
        // this.setState({item:this.props.item}, function(){
        //     console.log(this.state.item);
        // });
        //console.log(this.props.modalMode);
        // this.props.getLibrary({}).then((res)=>{
        //     console.log(res);
        // })

        this.props.getCountries({}).then((res)=>{
            this.setState({countries:res.data});
        })

        this.props.getLibrary({slug:"all", type:"image", perpage:10000}).then((res)=>{
            this.setState({images:res.data});
        })
    }

    render() {
        let titlePopup = "Add Artist";
        let deleteButton = "";
        //let defaultImageval = null;

        switch(this.props.modalMode){
            case "edit":
                titlePopup = "Edit Artist"
                deleteButton = <Button sx={{margin:'5px'}} variant="contained" endIcon={<Delete />} color="error" onClick={this.props.handleDelete}> Delete </Button>
                console.log(this.props.artist.idimage);
                //defaultImageval = null;//this.props.artist.idimage.toString();
                break;
            default:
                titlePopup = "Add Artist";
                break;
        }

        return (
            <Modal open={this.props.isModalOpen} onClose={this.props.closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ display:'flex', alignItems:'center', justifyContent:'center'}}
            >
                <Box sx={{backgroundColor:"#fff", padding:"10px", borderRadius: "8px", minHeight:'280px', minWidth:'480px', maxWidth:'640px'}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb:'10px', textAlign:'center'}}>{titlePopup}</Typography>
                    <Box component="form"
                        sx={{ '& .MuiTextField-root': { margin:'5px' } }}//width: 'calc(50% - 10px)'
                        // noValidate
                        autoComplete="off"
                        onSubmit={(this.props.modalMode === "edit")?this.props.handleUpdate:this.props.handleSubmit}
                    >
                    <div>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} autoFocus={true} value={this.props.artist.name || ""} required={true} id="input-name" label="Name" type="text" name="name" onChange={this.props.handleChange}/>
                    </div>
                    <div>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} value={this.props.artist.slug || ""} required={true} id="input-slug" label="Slug" type="text" name="slug" onChange={this.props.handleChange}/>
                    </div>
                    <div>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} 
                            id="input-description"
                            label="description"
                            name="description"
                            multiline
                            rows={3}
                            onChange={this.props.handleChange}
                            value={this.props.artist.description || ""}
                        />
                    </div>
                    <div>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} value={this.props.artist.weburl || ""} id="input-weburl" label="Website URL" type="text" name="weburl" onChange={this.props.handleChange}/>
                    </div>
                    <div>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} value={this.props.artist.instagramurl || ""} id="input-instagramurl" label="Instagram URL" type="text" name="instagramurl" onChange={this.props.handleChange}/>
                    </div>
                    <div>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} value={this.props.artist.mixcloudurl || ""} id="input-mixcloudurl" label="Mixcloud URL" type="text" name="mixcloudurl" onChange={this.props.handleChange}/>
                    </div>
                    <div>
                        <TextField sx={{ width: 'calc(100% - 10px)' }} value={this.props.artist.soundcloudurl || ""} id="input-soundcloudurl" label="Soundcloud URL" type="text" name="soundcloudurl" onChange={this.props.handleChange}/>
                    </div>
                    <div>
                        <Autocomplete
                            onChange={this.props.handleChange}                            
                            name="idcountry"
                            id="input-idcountry"
                            value={this.state.countries[this.props.artist.idcountry - 1] || null}
                            sx={{ width: 'calc(100% - 10px)' }}
                            options={this.state.countries}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option) => (
                                <Box name="idcountry" component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.initial.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.initial.toLowerCase()}.png 2x`}
                                    alt=""
                                />
                                {option.name} ({option.initial})
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                name="idcountry"
                                label="Choose a country"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                                />
                            )}
                        />
                    </div>
                    <div>
                        <Autocomplete
                            onChange={this.props.handleChange}                            
                            name="idimage"
                            id="input-idimage"
                            //value={defaultImageval || null}
                            sx={{ width: 'calc(100% - 10px)' }}
                            options={this.state.images}
                            autoHighlight
                            getOptionLabel={(option) => option.fullurl}
                            renderOption={(props, option) => (
                                <Box name="idimage" component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <img
                                    loading="lazy"
                                    height="50"
                                    src={`${option.fullurl}`}
                                    srcSet={`${option.fullurl}`}
                                    alt=""
                                />
                                {option.name}
                                </Box>
                            )}
                            renderInput={(params) => (
                                // console.log(params.inputProps.value);
                                <>
                                    {(params.inputProps.value !== "")?<img src={params.inputProps.value} height="50" alt=""/>:""}
                                    <TextField
                                    {...params}
                                    name="idimage"
                                    label="Choose an image"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <Box sx={{ display:'flex', alignItems:'right', justifyContent:'right'}}>
                        {deleteButton}
                        {(this.props.isLoading === true)?<Button sx={{margin:'5px'}} disabled={true} variant="contained" endIcon={<AddCircle />} type="submit"> Please Wait </Button>:<Button sx={{margin:'5px'}} variant="contained" endIcon={<AddCircle />} type="submit"> Submit </Button>}
                    </Box>
                    </Box>
                </Box>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCountry: artistData => dispatch(getCountry(artistData)),
        getLibrary: libraryData => dispatch(getLibrary(libraryData)),
        getCountries: artistData => dispatch(getCountries(artistData)),
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalArtist));