import axios from 'axios';
import {BASE_URL, BASE_VERSION} from "../config";

export const loadMoreProduct = (payload) => {
  return (dispatch) => {
    var perpage = payload.perpage;

    if(payload.slug === undefined || payload.slug === null){
      payload.slug = "all";
    }

    let paramPage = '?';
    if(payload.page !== undefined){
      paramPage += 'page='+payload.page;
    }
    if(perpage !== undefined){
      if(payload.page !== undefined){
        paramPage += '&perpage='+perpage;
      }else{
        paramPage += 'perpage='+perpage;
      }
    }else{
      paramPage += 'perpage='+process.env.REACT_APP_PERPAGE;
    }

    return( axios.get(BASE_URL+BASE_VERSION+'product/'+payload.slug+paramPage,
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, 
        validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        dispatch({ type: 'APPEND_PRODUCT', payload })
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const getProduct = (payload) => {
  return (dispatch) => {
    if(payload.slug === undefined || payload.slug === null){
      payload.slug = "all";
    }

    let paramPage = '?';
    if(payload.page !== undefined){
      paramPage += 'page='+payload.page;
    }
    if(payload.perpage !== undefined){
      if(payload.page !== undefined){
        paramPage += '&perpage='+payload.perpage;
      }else{
        paramPage += 'perpage='+payload.perpage;
      }
    }

    return( axios.get(BASE_URL+BASE_VERSION+'product/'+payload.slug+paramPage, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        dispatch({ type: 'SET_PRODUCT', payload })
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

export const getProductDetails = (payload) => {
  return (dispatch) => {
    if(payload.slug === undefined || payload.slug === null){
      payload.slug = "all";
    }

    let paramPage = '?';
    if(payload.page !== undefined){
      paramPage += 'page='+payload.page;
    }
    if(payload.perpage !== undefined){
      if(payload.page !== undefined){
        paramPage += '&perpage='+payload.perpage;
      }else{
        paramPage += 'perpage='+payload.perpage;
      }
    }

    return( axios.get(BASE_URL+BASE_VERSION+'product/'+payload.slug+paramPage, 
      {
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
        }, validateStatus: () => true
      })
      .then(function (response) {
        payload = response.data;
        // dispatch({ type: 'SET_PROGRAM', payload })
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      })
    )
  }
}

// export const addProgram = (payload) => {
//   return (dispatch) => {
//     return( axios.post(BASE_URL+BASE_VERSION+'program', {
//       name:payload.program.name,
//       slug:payload.program.slug,
//       description:payload.program.description,
//       schedule:payload.program.schedule,
//       idimage:payload.program.idimage,
//       idartist:payload.program.idartist,
//       artists:payload.program.artists
//     }, {
//       headers: { 
//         Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
//       }, validateStatus: () => true
//     })
//     .then((response) => {
//       payload = response.data;
//       return payload;
//     })
//     .catch((error) => {
//       console.log(error);
//       return error;
//     })
//     )
//   }
// }

// export const updateProgram = (payload) => {
//   return (dispatch) => {
//     return( axios.put(BASE_URL+BASE_VERSION+'program/'+payload.id, 
//       {
//         name:payload.program.name,
//         slug:payload.program.slug,
//         description:payload.program.description,
//         schedule:payload.program.schedule,
//         idimage:payload.program.idimage,
//         idartist:payload.program.idartist,
//         artists:payload.program.artists
//       }, 
//       {
//         headers: { 
//           Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
//         },
//         validateStatus: () => true
//       })
//       .then(function (response) {
//         payload = response.data;
//         return payload;
//       })
//       .catch(function (error) {
//         payload = error;
//         return payload;
//       })
//     )
//   }
// }

// export const deleteProgram = (payload) => {
//   return (dispatch) => {
//     return( axios.delete(BASE_URL+BASE_VERSION+'program/'+payload.id,  
//       {
//         headers: { 
//           Authorization: "Bearer "+window.localStorage.getItem('app_tt') 
//         },
//         validateStatus: () => true
//       })
//       .then(function (response) {
//         payload = response.data;
//         return payload;
//       })
//       .catch(function (error) {
//         payload = error;
//         return payload;
//       })
//     )
//   }
// }