import { Component } from "react"; //React
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class ScrollController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      err: null,
      // scroll: 0
    };

    // this.handleScroll = this.handleScroll.bind(this);
  }

  // componentDidMount() {
  //     window.addEventListener('scroll', this.handleScroll);
  // }

  // componentWillUnmount() {
  //     window.removeEventListener('scroll', this.handleScroll);
  // }

  // handleScroll(event) {
  //   let scrollTop = document.body.scrollTop;
  //   console.log(window.pageYOffset);

  //   // let itemTranslate = Math.min(0, scrollTop/3 - 60);
  //   // this.setState({
  //   //   scroll: itemTranslate
  //   // });
  // }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // logout: data => dispatch(logout(data))
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ScrollController)
);
