import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
} from "react-router-dom";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

class Loadings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:null
        };
    }

    render() {       
        return (
            <Box className="section-container-loading">
                <Typography>Loading...</Typography>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // loadMoreProgram: data => dispatch(loadMoreProgram(data)),
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Loadings));